<template>
  <div class="section-our-partinars">
    <b-container>
      <b-row>
        <b-col>
          <h2>{{ $t("ranking") }}</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="partiner-container">
            <VueSlickCarousel v-bind="settings" v-if="ranks.length !== 0">
              <div class="partiner-card" v-for="rank in ranks" :key="rank.id">
                <div class="partiner-card-content">
                  <img :src="getImage(rank.image)" alt="" />
                  <h3>Top {{ rank.number }}</h3>
                  <p>{{ rank.description }}</p>
                </div>
              </div>
            </VueSlickCarousel>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      ranks: [],
      settings: {
        dots: false,
        arrows: true,
        centerMode: false,
        infinite: true,
        initialSlide: 0,
        autoplay: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        draggable: true,
        rtl: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getRanks();
  },
  methods: {
    getRanks() {
      this.spinarlofing = true;
      this.axios
        .get(`international/rankings/clint?take=1000&skipe=0`)
        .then((res) => {
          this.ranks = res.data.items;
        });
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
  },
};
</script>
