<template>
  <div class="section-challenge" data-aos="fade">
    <b-container>
      <b-row>
        <b-col>
          <div class="section-challenge__content">
            <div>
              <p class="pl-5 text-center">
                {{ $t("alnisourDes") }}
              </p>
              <!-- <button-fill text="see changes in action" /> -->
            </div>
            <!-- <div class="accepted">
              <span>نبذة عن</span>
              <span>كلية النسور الجامعة</span>
            </div> -->
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import ButtonFill from "@/components/layout/ButtonFill.component.vue";
export default {
  // components: { ButtonFill },
};
</script>
