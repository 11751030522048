<template>
  <div class="application">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="council" />
    </header>
    <main>
      <section class="section-details" data-aos="fade-up">
        <section class="section-details__manage">
          <b-container>
            <div>
              <h4>{{ $t("councilManag.des") }}</h4>
              <ul>
                <li>{{ $t("councilManag.li1") }}</li>
                <li>
                  {{ $t("councilManag.li2") }}
                </li>
                <li>
                  {{ $t("councilManag.li3") }}
                </li>
              </ul>
            </div>
          </b-container>
        </section>
        <section class="section-details__missions">
          <b-container>
            <h4>{{ $t("councilMissions.des") }}</h4>
            <ol>
              <b-row>
                <b-col sm="12" md="6">
                  <div class="pl-5">
                    <li>{{ $t("councilMissions.li1") }}</li>
                    <li>
                      {{ $t("councilMissions.li2") }}
                    </li>
                    <li>
                      {{ $t("councilMissions.li3") }}
                    </li>
                    <li>{{ $t("councilMissions.li4") }}</li>
                    <li>{{ $t("councilMissions.li5") }}</li>
                    <li>
                      {{ $t("councilMissions.li6") }}
                    </li>
                    <li>
                      {{ $t("councilMissions.li7") }}
                    </li>
                    <li>
                      {{ $t("councilMissions.li8") }}
                    </li>
                  </div>
                </b-col>
                <b-col sm="12" md="6">
                  <div class="pr-5">
                    <li>
                      {{ $t("councilMissions.li9") }}
                    </li>
                    <li>{{ $t("councilMissions.li10") }}</li>
                    <li>
                      {{ $t("councilMissions.li11") }}
                    </li>
                    <li>
                      {{ $t("councilMissions.li12") }}
                    </li>
                    <li>{{ $t("councilMissions.li13") }}</li>
                    <li>
                      {{ $t("councilMissions.li14") }}
                    </li>
                    <li>
                      {{ $t("councilMissions.li5") }}
                    </li>
                  </div>
                </b-col>
              </b-row>
            </ol>
          </b-container>
        </section>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University College  - College Council`;
    document.querySelector('meta[name="title"]').setAttribute("content","College Council");
    document.querySelector('meta[name="description"]').setAttribute("content", "The members of the Board of Al-Nisour University College are the highest scientific and administrative body in the college:");
    document.querySelector('meta[property="og:title"]').setAttribute("content","College Council");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "The members of the Board of Al-Nisour University College are the highest scientific and administrative body in the college:");
    }else{
    document.title = `كلية النسور الجامعة  - مجلس الكلية`;
    document.querySelector('meta[name="title"]').setAttribute("content","مجلس الكلية");
    document.querySelector('meta[name="description"]').setAttribute("content", "اعضاء مجلس كلية النسور الجامعة هم اعلى هيئة علمية و ادارية في الكلية");
    document.querySelector('meta[property="og:title"]').setAttribute("content","مجلس الكلية");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "اعضاء مجلس كلية النسور الجامعة هم اعلى هيئة علمية و ادارية في الكلية");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
};
</script>
