<template>
  <div class="organazation">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="aboutUs.about17" />
    </header>
    <main>
      <b-container>
        <b-row>
          <b-col sm="12">
            <div class="image-container">
              <img src="../../assets/images/calender.jpg" alt="" />
              <!-- <img
                src="../../assets/images/blog-post-header.jpg"
                alt=""
                v-else
              /> -->
            </div>
          </b-col>
        </b-row>
      </b-container>
      <section class="section-deps">
        <b-container>
          <section class="section section-links">
            <h3>{{ $t("Additionallinks") }}</h3>
            <ul class="links">
              <li>
                <router-link
                  :to="{ name: 'agreements'}"
                >
                  {{ $t("agreements") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'fundation'}"
                >
                  {{ $t("foundation") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'management'}"
                >
                  {{ $t("Collegeadministration") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'organazation'}"
                >
                  {{ $t("organazationChart") }}
                </router-link>
              </li>
            </ul>
          </section>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      isMobile: false,
      lang: "ar",
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.querySelector('meta[name="title"]').setAttribute("content","University Calendar");
    document.querySelector('meta[name="description"]').setAttribute("content", "The academic year starts on 10/11/2021 and ends on 2/7/2022");
    document.querySelector('property="og:title"]').setAttribute("content","University Calendar");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "The academic year starts on 10/11/2021 and ends on 2/7/2022");
    document.title = ` Al-Nisour University College  - University Calendar`;
    }else{
    document.querySelector('meta[name="title"]').setAttribute("content","التقويم الجامعي");
    document.querySelector('meta[name="description"]').setAttribute("content", "يبدأ العام الدراسي  بتاريخ 2021/10/11 وينتهي 2022/7/2");
    document.querySelector('meta[property="og:title"]').setAttribute("content","التقويم الجامعي");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "يبدأ العام الدراسي  بتاريخ 2021/10/11 وينتهي 2022/7/2");
    document.title = `كلية النسور الجامعة  - التقويم الجامعي`;
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 

    this.lang = localStorage.getItem("lang");
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
};
</script>
