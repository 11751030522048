<template>
  <div class="application">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="energy management system" />
    </header>
    <main>
    <div class="section section-contact">
    <b-container fluid >
      <b-row>
        <b-col sm="12" md="6" >
          <div class="content">
            <div class="content-body">
              <img
                src="../../assets/images/energy1.jpg"
                alt=""
              />
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="content">
            <div class="content-body">
              <img
                src="../../assets/images/energy3.jpg"

                alt=""
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
    <section class="section-details">
        <b-container>
          <p class="mb-5">
            {{ $t("energy.e1") }}
          </p>
          <p class="mb-5">
            {{ $t("energy.e2") }}
          </p>
          <p class="mb-5">
            {{ $t("energy.e3") }}
          </p>
        </b-container>
      </section>
      <div class="section section-contact">
    <b-container fluid >
      <b-row>
        <b-col sm="12" md="6" >
          <div class="content">
            <div class="content-body">
              <img
                src="../../assets/images/energy4.jpg"
                alt=""
              />
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="content">
            <div class="content-body">
              <img
                src="../../assets/images/energy2.jpg"
                alt=""
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
      <section class="section-d">
        <b-container>
          <a class="links-button"
            :href="'Energy-Team'" 
            target="_blank"
          >
            <button-fill text="energy.e15" />
          </a>
          <a class="links-button"
            :href="'ISO50001'" 
            target="_blank"
          >
            <button-fill text="ISO50001" />
          </a>
          <a class="links-button"
            :href="'Energy-Researches'" 
            target="_blank"
          >
            <button-fill text="energy.e29" />
          </a>
          <a class="links-button"
            :href="'Energy-Workshops'" 
            target="_blank"
          >
            <button-fill text="energy.e31" />
          </a>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
    ButtonFill
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` `;
    document.querySelector('meta[name="title"]').setAttribute("content","");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }else{
    document.title = ``;
    document.querySelector('meta[name="title"]').setAttribute("content","");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","كلمة العميد");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "بكل فخر و بكل تواضع اصبحت كلية النسور الجامعة الفتية بعمرها ، العريقة بأدائها ، فقد اصبحت كليتنا و بفترة قياسية تضاهي الجامعات العراقية و العربية العريقة ، لما تملكه من ملاكات تدريسية يتميزون بمهارات و خبرات علمية كبيرة و شهادات مرموقة و من تجهيزات و معدات و  تقنيات و مختبرات حديثة و متطورة و من مناشئ");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
  methods: {},
};
</script>

<style scoped>
.button-fill {
  font-size: 80%;
}

.dean-image {
  display: block;
  object-fit: cover;
  width: 70%;
  height: 50rem;
  margin: 0 auto 6rem auto;
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.5);

  border: 8px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #e41748, #4918cd);
}
</style>
