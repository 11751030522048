<template>
  <section class="bg-video">
    <!-- <b-modal id="show-video" size="xl" hide-footer>
      <div class="d-block text-center">
        <video
          class="full-video"
          src="../../assets/videos/about-video.webm"
          autoplay
          controls
        ></video>
      </div>
    </b-modal> -->
    <!-- <div class="bg-video__overlay" id="overlay">
      <div class="play-btn" @click="watch">
        <i class="fas fa-play"></i>
      </div>
    </div> -->
    <!-- <img src="../../assets/images/bg.jpeg" alt="" /> -->
    <!-- <video
      class="short-video"
      id="video-10-s"
      src="../../assets/videos/video10s.mp4"
      autoplay
      muted
    ></video> -->
    <VueSlickCarousel v-bind="settings" v-if="popularPosts.length !== 0">
      <div
        class="carosel-post-card"
        v-for="post in popularPosts"
        :key="post.id"
      >
        <div class="carosel-post-content">
          <div class="carosel-post-text">
            <div class="inner-text">
              <h3>{{ shortenTitle(post.title) }}</h3>
              <p>{{ shortenText(post.sub_description) }}</p>
              <router-link :to="{ name: 'blog-post', params: { id: post.id } }">
                <button-fill text="readmore" />
              </router-link>
            </div>
          </div>
          <div class="overlay"></div>
          <img :src="getImage(post.image)" alt="" />
        </div>
      </div>
    </VueSlickCarousel>
  </section>
</template>

<script>
import { filesUrl } from "@/main";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
// import "video.js/dist/video-js.css";
// import "@videojs/themes/dist/fantasy/index.css";
// import { videoPlayer } from "vue-video-player";
// import "videojs-contrib-hls";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: {
    // videoPlayer,
    VueSlickCarousel,
    ButtonFill,
  },
  data() {
    return {
      popularPosts: [],
      // playerOptions: {
      //   height: "560",
      //   width: "770",
      //   autoplay: true,
      //   muted: false,
      //   language: "en",
      //   playbackRates: [0.7, 1.0, 1.5, 2.0],
      //   sources: [
      //     {
      //       type: "video/mp4",
      //       src: "https://www.youtube.com/embed/Y3-YLxpwWBw",
      //     },
      //   ],
      // },
      settings: {
        dots: false,
        arrows: true,
        centerMode: false,
        infinite: true,
        initialSlide: 0,
        autoplay: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: false,
        draggable: true,
        rtl: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  mounted() {
    this.getPopularPosts();
    // let overlay = document.getElementById("overlay");
    // setTimeout(() => {
    //   overlay.style.opacity = "1";
    //   overlay.style.visibility = "visible";
    // }, 10500);
  },
  methods: {
    // watch() {
    //   this.playerOptions.sources[0].src = `${file.key}`;
    //   this.$bvModal.show("show-video");
    // },
    getPopularPosts() {
      this.axios
        .get("news/clint?skip=0&take=10&slider=1")
        .then((res) => {
          console.log(res)
          this.popularPosts = res.data.items;
        });
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    shortenTitle(text) {
      if (text !== null) {
        if (text.length > 80) {
          let maxLength = 80;
          let trimmedString = text.substr(0, maxLength);
          return trimmedString;
        } else {
          return text;
        }
      } else {
        return "";
      }
    },
    shortenText(text) {
      if (text !== null) {
        if (text.length > 100) {
          let maxLength = 100;
          let trimmedString = text.substr(0, maxLength);
          return trimmedString;
        } else {
          return text;
        }
      } else {
        return "";
      }
    },
  },
};
</script>
