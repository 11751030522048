<template>
  <div class="course-profile">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header />
    </header>
    <main class="course-body">
      <section class="course-details" v-if="courseInfo">
        <b-container class="bg-white">
          <div class="course-header">
            <img :src="getImage(courseInfo.image)" alt="" />
            <h2>{{ courseInfo.name }}</h2>
            <div class="date">
              <ul>
                <li>
                  <span><i class="fas fa-calendar-alt"></i></span>
                  <span>{{ $t("date") }}:</span>
                  <span>{{ shortenDate(courseInfo.created_at) }}</span>
                </li>
                <li>
                  <span><i class="fas fa-dollar-sign"></i></span>
                  <span>{{ $t("price") }}</span>
                  <span v-if="courseInfo.price !== '0'">{{
                    courseInfo.price
                  }}</span>
                  <span v-else>{{ $t("general.free") }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="course-info">
            <h3>{{ $t("details") }}:</h3>
            <p>
              {{ courseInfo.description }}
            </p>
            <div class="social-links">
              <ShareNetwork
                network="facebook"
                :url="href"
                title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                quote="The hot reload is so fast it\'s near instant. - Evan You"
                hashtags="vuejs,vite"
              >
                <span><i class="fab fa-facebook-f"></i></span>
              </ShareNetwork>
              <ShareNetwork
                network="twitter"
                :url="href"
                title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                quote="The hot reload is so fast it\'s near instant. - Evan You"
                hashtags="vuejs,vite"
              >
                <span><i class="fab fa-twitter"></i></span>
              </ShareNetwork>
              <ShareNetwork
                network="email"
                :url="href"
                title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                quote="The hot reload is so fast it\'s near instant. - Evan You"
                hashtags="vuejs,vite"
              >
                <span><i class="fas fa-envelope"></i></span>
              </ShareNetwork>
              <ShareNetwork
                network="linkedin"
                :url="href"
                title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                quote="The hot reload is so fast it\'s near instant. - Evan You"
                hashtags="vuejs,vite"
              >
                <span><i class="fab fa-linkedin-in"></i></span>
              </ShareNetwork>
            </div>
          </div>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      courseInfo: null,
      isMobile: false,
    };
  },
  mounted() {
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getCourseInfo();
  },
  methods: {
    getCourseInfo() {
      this.axios.get(`course/show/${this.$route.params.id}`).then((res) => {
        this.courseInfo = res.data;
        this.changeMeta(res.data);
        console.log(this.courseInfo);
      });
    },
    getImage(path) {
      return `https://ahmeddev.online/alnisour-back-end/public/${path}`;
    },
    shortenDate(date) {
      let newDate = date.split("");
      newDate = newDate.slice(0, 10);
      newDate = newDate.join("");
      return newDate;
    },
    changeMeta(item){
      document.querySelector('meta[name="title"]').setAttribute("content",`${item.name}`);
      document.querySelector('meta[name="description"]').setAttribute("content", `${item.description}`);
      document.querySelector('meta[property="og:title"]').setAttribute("content",`${item.name}`);
      document.querySelector('meta[property="og:description"]').setAttribute("content", `${item.description}`);
      document.querySelector('meta[property="og:image"]').setAttribute("content", `${filesUrl}${item.image}`);
      // document.getElementById("icon").setAttribute("href",`${filesUrl}${item.image}`); 
      if(localStorage.getItem("lang")=="ar"){
      document.title = `كلية النسور الجامعة  - ${item.name}`;
      }else{
      document.title = ` Al-Nisour University College  - ${item.name}`;
      }
    }
  },
};
</script>
