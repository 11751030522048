<template>
  <div class="section section-contact">
    <b-container fluid class="px-10">
      <b-row>
        <b-col sm="12" md="6" data-aos="fade-up">
          <div class="content">
            <div class="content-header">
              <h3>{{ $t("fifthYear") }}</h3>
            </div>
            <div class="content-body">
              <img
                src="../../assets/images/img2.jpeg"
                alt=""
              />
              <p>
                {{ $t("planingDes") }}
              </p>
              <div class="content-btns">
                <router-link :to="{ name: 'fifth-plan' }">
                  <button-fill text="readmore" />
                </router-link>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6" data-aos="fade-down">
          <div class="content">
            <div class="content-header">
              <h3>{{ $t("sustainable") }}</h3>
            </div>
            <div class="content-body">
              <img
                src="../../assets/images/q.jpg"
                alt=""
              />
              <p>
                {{ $t("growthDes") }}
              </p>
              <div class="content-btns">
                <router-link :to="{ name: 'continuse-development' }">
                  <button-fill text="readmore" />
                </router-link>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
export default {
  components: { ButtonFill },
};
</script>
