import ar from "./locales/ar.json";
import en from "./locales/en.json";
import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export default new VueI18n({
  locale: localStorage.getItem("lang") || "ar",
  messages: {
    ar: ar,
    en: en,
  },
});

// function loadLocaleMessages() {
//   const locales = require.context(
//     "./locales",
//     true,
//     /[A-Za-z0-9-_,\s]+\.json$/i
//   );
//   const messages = {};
//   locales.keys().forEach((key) => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i);
//     if (matched && matched.length > 1) {
//       const locale = matched[1];
//       messages[locale] = locales(key);
//     }
//   });
//   return messages;
// }

// export default new VueI18n({
//   locale: "ar",
//   fallbackLocale: "ar",
//   messages: loadLocaleMessages(),
// });
