<template>
  <div class="fundation organazation">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="Abouttheestablishmentofthecollege" />
    </header>
    <main>
      <div class="fundation__body">
        <section class="py-10 section-vision">
          <b-container>
            <b-row>
              <b-col sm="12" md="6" data-aos="fade-left">
                <div class="video">
                  <video
                    src="@/assets/videos/about-video.webm"
                    autoplay
                    muted
                    loop
                    controls
                  ></video>
                </div>
              </b-col>
              <b-col
                sm="12"
                md="6"
                class="d-flex align-items-center"
                data-aos="fade-right"
              >
                <div class="text">
                  <h3>{{ $t("aboutUs.about1") }}</h3>
                  <p>
                    - {{ $t("aboutUs.about2") }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </section>
        <section class="py-10 section-message">
          <b-container>
            <b-row>
              <b-col
                sm="12"
                md="6"
                class="d-flex align-items-center"
                data-aos="fade-left"
              >
                <div class="text">
                  <h3>{{ $t("aboutUs.about3") }}</h3>
                  <p>
                    <span>- {{ $t("aboutUs.about4") }}</span>
                  </p>
                </div>
              </b-col>
              <b-col sm="12" md="6" data-aos="fade-right">
                <div class="image">
                  <img
                    src="https://vibion-vue.envytheme.com/img/about-img1.0c8d187d.jpg"
                    alt=""
                  />
                </div>
              </b-col>
            </b-row>
          </b-container>
        </section>
        <section class="py-10 section-gols">
          <b-container>
            <b-row>
              <b-col cols="12" class="d-flex align-items-center">
                <div>
                  <h3>{{ $t("aboutUs.about6") }}</h3>
                  <ul>
                    <li data-aos="fade-left">
                    {{ $t("aboutUs.about8") }}
                    </li>
                    <li data-aos="fade-right">
                      {{ $t("aboutUs.about9") }}
                    </li>
                    <li data-aos="fade-left">
                      {{ $t("aboutUs.about10") }}
                    </li>
                    <li data-aos="fade-right">
                      {{ $t("aboutUs.about11") }}
                    </li>
                    <li data-aos="fade-left">
                      {{ $t("aboutUs.about12") }}
                    </li>
                    <li data-aos="fade-right">
                      {{ $t("aboutUs.about14") }}
                    </li>
                  </ul>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </section>
        <section class="py-10 section-message">
          <b-container>
            <b-row>
              <b-col
                sm="12"
                md="12"
                class="d-flex align-items-center"
                data-aos="fade-left"
              >
                <div class="text">
                  <span><h3>{{ $t("aboutUs.about18") }}</h3>
                  <p>
                    <span><p style="font-size: 20px;font-weight: bold;">{{ $t("aboutUs.about19") }} :-</p> {{ $t("aboutUs.about25") }}</span>
                    <span><p style="font-size: 20px;font-weight: bold;">{{ $t("aboutUs.about20") }} :-</p> {{ $t("aboutUs.about26") }}</span>
                    <span><p style="font-size: 20px;font-weight: bold;">{{ $t("aboutUs.about21") }} :-</p> {{ $t("aboutUs.about27") }}</span>
                    <span><p style="font-size: 20px;font-weight: bold;">{{ $t("aboutUs.about22") }} :-</p> {{ $t("aboutUs.about28") }}</span>
                    <span><p style="font-size: 20px;font-weight: bold;">{{ $t("aboutUs.about23") }} :-</p> {{ $t("aboutUs.about29") }}</span>
                    <span><p style="font-size: 20px;font-weight: bold;">{{ $t("aboutUs.about24") }} :-</p> {{ $t("aboutUs.about30") }}</span>
                  </p></span>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </section>
        <section class="py-10 section-dev">
          <b-container>
            <b-row>
              <b-col
                cols="12"
                class="d-flex align-items-center"
                data-aos="fade-up"
              >
                <div>
                  <h3>{{ $t("aboutUs.about15") }}</h3>
                  <p>
                    {{ $t("aboutUs.about16") }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </section>
      </div>
      <section class="section-deps">
        <b-container>
          <section class="section section-links">
            <h3>{{ $t("Additionallinks") }}</h3>
            <ul class="links">
              <li>
                <router-link
                  :to="{ name: 'agreements'}"
                >
                  {{ $t("agreements") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'organazation'}"
                >
                  {{ $t("organazationChart") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'management'}"
                >
                  {{ $t("Collegeadministration") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'calender'}"
                >
                  {{ $t("aboutUs.about17") }}
                </router-link>
              </li>
            </ul>
          </section>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University College  - About the establishment of the college`;
    document.querySelector('meta[name="title"]').setAttribute("content","About the establishment of the college");
    document.querySelector('meta[name="description"]').setAttribute("content", "Vision of Al-Nisour University College");
    document.querySelector('meta[property="og:title"]').setAttribute("content","About the establishment of the college");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "Vision of Al-Nisour University College");
    }else{
    document.title = `كلية النسور الجامعة  - عن تأسيس الكلية`;
    document.querySelector('meta[name="title"]').setAttribute("content","عن تأسيس الكلية");
    document.querySelector('meta[name="description"]').setAttribute("content", "رؤية كلية النسور الجامعة");
    document.querySelector('meta[property="og:title"]').setAttribute("content","عن تأسيس الكلية");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "رؤية كلية النسور الجامعة");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
};
</script>
