<template>
    <div class="tracking">
      <header>
        <topbar />
        <navbar v-show="isMobile !== true" />
        <mobile-nav v-show="isMobile" />
        <section-header text="tracking" />
      </header>
      <main>
         <section class="tracking-body">
          <div class="container-center">
            <div class="tracking-headertext">{{ $t("tracking") }}</div>
          </div>
          <div class="container-center">
            <div class="custom-input">
            <input type="text" placeholder="ادخل كود الطلب الخاص بك هنا">
          </div>
          </div>
        </section>
      </main>
      <footer>
        <footer-component />
      </footer>
    </div>
  </template>
  
  <script>
  // import { filesUrl } from "@/main";
  import MobileNav from "@/components/layout/MobileNav.component.vue";
  import Topbar from "@/components/layout/Topbar.component.vue";
  // import ButtonFill from "@/components/layout/ButtonFill.component.vue";
  import Navbar from "@/components/layout/Navbar.component.vue";
  import SectionHeader from "@/components/layout/SectionHeader.component.vue";
  import FooterComponent from "@/components/layout/FooterComponent.component.vue";
  export default {
    components: {
      Topbar,
      Navbar,
      SectionHeader,
      FooterComponent,
      // ButtonFill,
      MobileNav,
    },
    data() {
      return {
        name: "",
        path: "lib/pdfjs/web/viewer.html",
        isMobile: false,
        host: window.location.host,
      };
    },
    mounted() {
      if(localStorage.getItem("lang")=="en"){
      document.title = ` Al-Nisour University College  - Sustainable Development`;
      document.querySelector('meta[name="title"]').setAttribute("content","Sustainable Development");
      document.querySelector('meta[name="description"]').setAttribute("content", "The subject of sustainable development has captured the world’s attention over the past years at the level of the global economic, social and environmental area, where development sustainability has become a global school of thought spread in most countries of the developing and industrialized world alike. It is adopted by popular and official figure and demands its application. Because of the summits and conferences since its inception, but this concept is still ambiguous as a concept, philosophy and process. I am pleased to extend to you my sincere greetings, appreciation and respect to all members of Al-Nisour University College from my distinguished colleagues, respected faculty members, and its staff members (administrative and technicians), and I extend my warmest greetings to my sons and dear students, wishing them excellence and success.");
      document.querySelector('meta[property="og:title"]').setAttribute("content","Sustainable Development");
      document.querySelector('meta[property="og:description"]').setAttribute("content", "The subject of sustainable development has captured the world’s attention over the past years at the level of the global economic, social and environmental area, where development sustainability has become a global school of thought spread in most countries of the developing and industrialized world alike. It is adopted by popular and official figure and demands its application. Because of the summits and conferences since its inception, but this concept is still ambiguous as a concept, philosophy and process. I am pleased to extend to you my sincere greetings, appreciation and respect to all members of Al-Nisour University College from my distinguished colleagues, respected faculty members, and its staff members (administrative and technicians), and I extend my warmest greetings to my sons and dear students, wishing them excellence and success.");
      }else{
      document.title = `كلية النسور الجامعة  - التنمية المستدامة`;
      document.querySelector('meta[name="title"]').setAttribute("content","التنمية المستدامة");
      document.querySelector('meta[name="description"]').setAttribute("content", "لقد استحوذ موضوع التنمية المستدامة اهتمام العالم من خلال السنوات المنصرمة و هذا على صعيد الساحة الاقتصادية و الاجتماعية و البيئية العالمية ، حيث اصبحت الاستدامة التنموية مدرسة فكرية عالمية تنتشر في معظم دول العالم النامي و الصناعي على حد سواء تتبناها هيئات شعبية و رسمية و تطالب بتطبيقها فعقدت من اجلها");
      document.querySelector('meta[property="og:title"]').setAttribute("content","التنمية المستدامة");
      document.querySelector('meta[property="og:description"]').setAttribute("content", "لقد استحوذ موضوع التنمية المستدامة اهتمام العالم من خلال السنوات المنصرمة و هذا على صعيد الساحة الاقتصادية و الاجتماعية و البيئية العالمية ، حيث اصبحت الاستدامة التنموية مدرسة فكرية عالمية تنتشر في معظم دول العالم النامي و الصناعي على حد سواء تتبناها هيئات شعبية و رسمية و تطالب بتطبيقها فعقدت من اجلها");
      }
      document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
      // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
      if (window.innerWidth > 991) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    methods: {},
  };
  </script>
  
  <style scoped>
  .button-fill {
    font-size: 80%;
  }
  </style>
  