<template>
  <div class="blog-post">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <post-header
        :title="post.title"
        :date="shortenDate(post.created_at)"
        :auther="post.user.full_name"
      />
    </header>
    <main>
      <b-container class="mt-5">
        <b-row>
          <b-col sm="12" md="8" class="p-0">
            <post-body :comments="comments" />
          </b-col>
          <b-col sm="12" md="4" class="p-0">
            <post-sidebar
              :posts="posts"
              :popularPosts="popularPosts"
              :tags="tags"
            />
          </b-col>
        </b-row>
      </b-container>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
import PostHeader from "@/components/blog/PostHeader.component.vue";
import PostBody from "@/components/blog/PostBody.component.vue";
import PostSidebar from "@/components/blog/PostSidebar.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    PostHeader,
    PostBody,
    PostSidebar,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      post: [],
      comments: [],
      posts: [],
      popularPosts: [],
      tags: [],
      isMobile: false,
    };
  },
  mounted() {
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getPost();
    this.getAllPosts();
    this.getAllTags();
    this.getPopularPosts();
  },
  methods: {
    getPost() {
      this.axios.get(`news/clint/${this.$route.params.id}`).then((res) => {
        this.post = res.data;
        this.changeMeta(res.data);
        this.comments = res.data.comment.reverse();
      });
    },
    getAllPosts() {
      this.axios.get("news/clint?skip=0&take=10").then((res) => {
        this.posts = res.data.items;
      });
    },
    getAllTags() {
      this.axios.get("tag/clint?skip=0&take=10").then((res) => {
        this.tags = res.data.items;
      });
    },
    getPopularPosts() {
      this.axios.get("news/list/viewSort?skip=0&take=3").then((res) => {
        this.popularPosts = res.data.items;
      });
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    shortenDate(date) {
      let newDate = date.split("");
      newDate = newDate.slice(0, 10);
      newDate = newDate.join("");
      return newDate;
    },
    changeMeta(item){
      document.querySelector('meta[name="title"]').setAttribute("content",`${item.title}`);
      document.querySelector('meta[name="description"]').setAttribute("content", `${item.description}`);
      document.querySelector('meta[property="og:title"]').setAttribute("content",`${item.title}`);
      document.querySelector('meta[property="og:description"]').setAttribute("content", `${item.description}`);
      document.querySelector('meta[property="og:image"]').setAttribute("content", `${filesUrl}${item.image}`);
      // document.getElementById("icon").setAttribute("href",`${filesUrl}${item.image}`); 
      if(localStorage.getItem("lang")=="ar"){
      document.title = `كلية النسور الجامعة  - ${item.title}`;
      }else{
      document.title = ` Al-Nisour University College  - ${item.title}`;
      }
    }
  },
};
</script>
