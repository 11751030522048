<template>
  <div class="post-sidebar">
    <div class="post-sidebar__popular-posts">
      <h3>{{ $t("PopularPosts") }}</h3>
      <ul>
        <li v-for="(post, index) in popularPosts" :key="post.id">
          <div
            class="d-flex align-items-center"
            data-aos="fade-left"
            :data-aos-delay="`${index + 5}00`"
          >
            <a href="#" @click.prevent="toPostId(post.id)">
              <img :src="getImage(post.image)" alt="" />
            </a>
            <div class="post-info">
              <p>{{ shortenDate(post.created_at) }}</p>
              <a href="#" @click.prevent="toPostId(post.id)">
                <h4>{{ shortenText(post.title) }}</h4>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="post-sidebar__recent-posts">
      <h3>{{ $t("Latestposts") }}</h3>
      <ul>
        <li v-for="(post, index) in filteredPosts" :key="post.id">
          <div
            class="d-flex align-items-center"
            data-aos="fade-left"
            :data-aos-delay="`${index + 5}00`"
          >
            <a href="#" @click.prevent="toPostId(post.id)">
              <img :src="getImage(post.image)" alt="" />
            </a>
            <div class="post-info">
              <p>{{ shortenDate(post.created_at) }}</p>
              <a href="#" @click.prevent="toPostId(post.id)">
                <h4>{{ shortenText(post.title) }}</h4>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- <div class="post-sidebar__categories">
      <h3>اشهر الاقسام</h3>
      <ul>
        <li>تكنولوجيا</li>
        <li>ادارة الاعمال</li>
        <li>روبوتات</li>
        <li>حسابات</li>
      </ul>
    </div> -->
    <div class="post-sidebar__tags">
      <h3>{{ $t("tags") }}</h3>
      <div class="tags-container">
        <span v-for="tag in tags" :key="tag.id">
          <router-link
            :to="{ name: 'news-by-tag', params: { tag_id: tag.id } }"
          >
            {{ tag.name }} ({{ tag.news_count }})
          </router-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
export default {
  props: ["posts", "popularPosts", "tags"],
  computed: {
    filteredPosts: function () {
      return this.posts.slice(0, 4);
    },
  },
  methods: {
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    shortenDate(date) {
      let newDate = date.split("");
      newDate = newDate.slice(0, 10);
      newDate = newDate.join("");
      return newDate;
    },
    toPostId(id) {
      if (this.$route.params.id == id) {
        this.$store.dispatch("getDepId", id);
      } else {
        this.$store.dispatch("getDepId", id);
        this.$router.push({ name: "blog-post", params: { id: id } });
      }
    },
    shortenText(text) {
      if (text !== null) {
        if (text.length > 50) {
          let maxLength = 50;
          let trimmedString = text.substr(0, maxLength);
          return trimmedString + " ...";
        } else {
          return text;
        }
      } else {
        return "";
      }
    },
  },
};
</script>
