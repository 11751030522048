var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"staff"},[_c('header',[_c('topbar'),_c('navbar',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile !== true),expression:"isMobile !== true"}]}),_c('mobile-nav',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}]}),_c('section-header',{attrs:{"text":"DepartmenTeachers"}})],1),_c('main',[_c('section',{staticClass:"staffs",attrs:{"id":"teachers"}},[_c('b-container',[_c('b-row',_vm._l((_vm.teachers),function(teacher,index){return _c('b-col',{key:teacher.id,staticClass:"mb-5",attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"staff__card",attrs:{"data-aos":"fade","data-aos-delay":`${index + 5}00`}},[_c('div',{staticClass:"staff__card--imageplaceholder"},[_c('img',{attrs:{"src":_vm.getImage(teacher.image),"alt":""}}),_c('div',{staticClass:"overlay"},[_c('router-link',{attrs:{"to":{
                      name: 'staff-profile',
                      params: { id: teacher.id },
                    }}},[_c('button-fill',{attrs:{"text":"readmore"}})],1)],1)]),_c('div',{staticClass:"staff__card--content"},[_c('h3',[_c('router-link',{attrs:{"to":{
                      name: 'staff-profile',
                      params: { id: teacher.id },
                    }}},[_vm._v(" "+_vm._s(teacher.name)+" ")])],1),_c('p',{staticClass:"spicialized"},[_vm._v(" "+_vm._s(teacher.general_specialty)+", "+_vm._s(teacher.accurate_specialty)+" ")]),_c('p',{staticClass:"details"},[_vm._v(" "+_vm._s(teacher.description)+" ")])])])])}),1)],1)],1),_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center mb-5"},[_c('b-pagination',{staticClass:"mx-auto",attrs:{"size":"lg","total-rows":_vm.totaCount,"per-page":_vm.perPage,"aria-controls":"teachers"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1),_c('footer',[_c('footer-component')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }