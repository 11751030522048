<template>
  <div class="post-body">
    <div class="post-body__image">
      <img :src="getImage(post.image)" alt="" />
    </div>
    <div class="post-body__post-info">
      <p>
        <span v-if="post.length !== 0"
          >{{ $t("Posted") }} {{ post.user.full_name }}</span
        >
        <span> - </span>
        <span v-if="post.length !== 0"
          >{{ $t("date") }}: {{ shortenDate(post.created_at) }}</span>
      </p>
      <h2>{{ post.title }}</h2>
    </div>
    <div class="post-body__post-text" id="post-text"></div>
    <post-sheare v-if="post.length !== 0" :tags="post.tags" />
    <div class="row">
     <b-col sm="12" md="6" class="p-0"
     v-for="image in post.images"
              :key="image.id"
     >
    <div class="post-body_ex_image">
      <img :src="getImage(image.link)" alt="" />
    </div>
    </b-col>
    </div>
    
    <post-comments :comments="comments" />
  </div>
</template>

<script>
import Quill from "quill";
import { mapState } from "vuex";
import { filesUrl } from "@/main";
import PostSheare from "@/components/blog/PostSheare.component.vue";
import PostComments from "@/components/blog/PostComments.component.vue";
export default {
  components: {
    PostSheare,
    PostComments,
  },
  props: ["comments"],
  data() {
    return {
      post: [],
    };
  },
  computed: { ...mapState(["depId"]) },
  watch: {
    depId: async function () {
      await this.getPost();
    },
  },
  async mounted() {
    await this.getPost();
  },
  methods: {
    async getPost() {
      await this.axios
        .get(`news/clint/${this.$route.params.id}`)
        .then((res) => {
          this.post = res.data;
          let data = [];
          data.push(JSON.parse(this.post.description));
          let article = document.createElement("article");
          let quill = new Quill(article, {});
          quill.enable(false);
          quill.setContents(data[0].ops);
          let discription = document.getElementById("post-text");
          discription.innerHTML = "";
          discription.appendChild(article);
        });
    },
    shortenDate(date) {
      let newDate = date.split("");
      newDate = newDate.slice(0, 10);
      newDate = newDate.join("");
      return newDate;
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
  },
};
</script>
