<template>
  <div class="staff-profile">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header />
    </header>
    <main class="staff-profile__body">
      <section class="staff-profile__personal-info">
        <b-container>
          <div class="user-info-container">
            <div class="image-placeholder">
              <img :src="getImage(teacher.image)" alt="" />
            </div>
            <div class="user-info">
              <h3>{{ teacher.name }}</h3>
              <p>
                {{ teacher.general_specialty }},
                {{ teacher.accurate_specialty }}
              </p>
              <p class="teacher-des">
                {{ teacher.description }}
              </p>
              <div class="d-flex justify-content-center">
                <a href="#" @click="cv(teacher.file_cv)" v-if="teacher.file_cv">
                  <button-fill text="curriculum" />
                </a>
              </div>
            </div>
          </div>
        </b-container>
      </section>
      <section class="staff-profile__table">
        <b-container>
          <div class="table-container">
            <b-row
              ><b-col sm="12" md="4">
                <ul class="more-info">
                  <li>{{ $t("name") }}: {{ teacher.name }}</li>
                  <li>{{ $t("dep") }}: {{ teacher.depatrment.name }}</li>
                  <li>{{ $t("email") }}: {{ teacher.email }}</li>
                  <li>
                    {{ $t("graduationDegree") }}:
                    {{ teacher.graduation_degree }}
                  </li>
                  <li>
                    {{ $t("generalSpecialty") }}:
                    {{ teacher.general_specialty }}
                  </li>
                  <li>
                    {{ $t("accurateSpecialty") }}:
                    {{ teacher.accurate_specialty }}
                  </li>
                  <li v-if="teacher.scopus!=null &&teacher.scopus!='null'&&teacher.scopus!=''">
                    {{ $t("scopus") }}
                    <a
                      style="color: #fff !important"
                      :href="teacher.scopus"
                      target="_blank"
                    >
                      {{ teacher.scopus }}
                    </a>
                  </li>
                  <li  v-if="teacher.research_gate!=null &&teacher.research_gate!='null'&&teacher.research_gate!=''">
                    {{ $t("research_gate") }}:
                    <a
                      style="color: #fff !important"
                      :href="teacher.research_gate"
                      target="_blank"
                    >
                      {{ teacher.research_gate }}
                    </a>
                  </li>
                  <li v-if="teacher.academia!=null &&teacher.academia!='null'&&teacher.academia!=''">
                    {{ $t("academia") }}:
                    <a
                      style="color: #fff !important"
                      :href="teacher.academia"
                      target="_blank"
                    >
                      {{ teacher.academia }}
                    </a>
                  </li>
                  <li v-if="teacher.publon!=null &&teacher.publon!='null'&&teacher.publon!=''">
                    {{ $t("publon") }}:
                    <a
                      style="color: #fff !important"
                      :href="teacher.publon"
                      target="_blank"
                    >
                      {{ teacher.publon }}
                    </a>
                  </li>
                  <li v-if="teacher.orcid!=null &&teacher.orcid!='null'&&teacher.orcid!=''">
                    {{ $t("orcid") }}:
                    <a
                      style="color: #fff !important"
                      :href="teacher.orcid"
                      target="_blank"
                    >
                      {{ teacher.orcid }}
                    </a>
                  </li>
                  <li v-if="teacher.google_scholar!=null &&teacher.google_scholar!='null'&&teacher.google_scholar!=''">
                    {{ $t("google_scholar") }}:
                    <a
                      style="color: #fff !important"
                      :href="teacher.google_scholar"
                      target="_blank"
                    >
                      {{ teacher.google_scholar }}
                    </a>
                  </li>
                </ul>
              </b-col>
              
              <b-col sm="12" md="8">
              <b-row>
              <b-col sm="12" md="12">
              <div class="tbl-header">
                  <table cellpadding="0" cellspacing="0" border="0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{{ $t("researchTitle") }}</th>
                        <th>{{ $t("date") }}</th>
                        <th>{{ $t("magazin2") }}</th>
                        <th>{{ $t("researchLink") }}</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div class="tbl-content">
                  <table cellpadding="0" cellspacing="0" border="0">
                    <tbody>
                      <tr
                        v-for="(research, index) in teacher.research"
                        :key="research.id"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ research.title }}</td>
                        <td>{{ research.publish_date }}</td>
                        <td>{{ research.magazine }}</td>
                        <td>
                          <a :href="research.link"
                            ><i class="fas fa-link"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                </b-col>
                <b-col sm="12" md="12"  style="margin-top: 20px !important;">

              <div class="tbl-header">
                  <table cellpadding="0" cellspacing="0" border="0">
                    <thead>
                      <tr>
                        <th>#</th>
                      <th>{{ $t("lectureName") }}</th>
                      <th>{{ $t("studentResearch.dep") }}</th>
                      <th>{{ $t("stage") }}</th>
                      <th>{{ $t("numOfLecture") }}</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div class="tbl-content">
                  <table cellpadding="0" cellspacing="0" border="0">
                    <tbody>
                      <tr
                        v-for="(item, index) in items"
                        :key="item.id"
                      >
                         <td>{{ index + 1 }}</td>
                      <td><a @click="getFiles(item.id)">{{ item.name }}</a></td>
                      <td>{{ item.department.name }}</td>
                      <td >
                      {{ item.stage.name }}
                      </td>
                      <td >
                      {{ item.file_count }}
                      </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
              <b-col sm="12" md="12"  style="margin-top: 20px !important;">
              <div class="lecturtes">
      <section class="section-files" v-if="files.length !== 0">
        <b-container>
          <b-row>
            <b-col
              sm="12"
              md="6"
              class="mb-3"
              v-for="file in files"
              :key="file.id"
            >
              <div class="file">
                <a
                  :href="
                    'https://www.nuc.edu.iq/Al-Nisour/public/' +
                    file.file
                  "
                  target="_blank"
                  >{{ file.name }}</a
                >
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      </div>
              </b-col>
              </b-row>
                
              </b-col>
              
            </b-row>
          </div>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
import MobileNav from "@/components/layout/MobileNav.component.vue";
import { filesUrl } from "@/main.js";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    ButtonFill,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      teacher: null,
      isMobile: false,
      items:[],
      files:[]
    };
  },
  mounted() {
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getTeacher();
    this.getLectures();
  },
  methods: {
    getLectures() {
      this.axios.get(`lecture/clint?take=100&skip=0&teacher_id=${this.$route.params.id}`)
          .then((res) => {
        this.items = res.data.items;
        console.log(res.data.items[0]);
      });
    },
    getTeacher() {
      this.axios.get(`teacher/show/${this.$route.params.id}`).then((res) => {
        this.teacher = res.data;
        this.changeMeta(res.data[0]);
      });
    },
    getFiles(id) {
      this.axios.get(`lecture/clint/${id}`).then((res) => {
        this.files = res.data.files;
      });
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    cv(path) {
      window.open(`${filesUrl}/${path}`, "_blank");
    },
    changeMeta(item) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", `${item.description}`);
      document
        .querySelector('meta[property="og:description"]')
        .setAttribute("content", `${item.description}`);
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute("content", `${filesUrl}${item.image}`);
      document
        .getElementById("icon")
        .setAttribute("href", `${filesUrl}${item.image}`);
      if (localStorage.getItem("lang") == "ar") {
        document.title = `كلية النسور الجامعة  -  ${item.name} `;
        document
          .querySelector('meta[name="title"]')
          .setAttribute("content", `  - ${item.name}`);
      } else {
        document.title = ` Al-Nisour University College  - ${item.name} `;
        document
          .querySelector('meta[property="og:title"]')
          .setAttribute("content", `${item.name} `);
      }
    },
  },
};
</script>
<!-- <main class="staff-profile__body">
      <section class="staff-profile__table">
        <b-container>
          <div class="table-container">
            <b-row>
              <b-col sm="12" md="4">
                <ul class="more-info">
                  <li>{{ $t("name") }}: {{ teacher.name }}</li>
                  <li>{{ $t("email") }}: {{ teacher.email }}</li>
                  <li>
                    {{ $t("graduationDegree") }}:
                    {{ teacher.graduation_degree }}
                  </li>
                  <li>
                    {{ $t("generalSpecialty") }}:
                    {{ teacher.general_specialty }}
                  </li>
                  <li>
                    {{ $t("accurateSpecialty") }}:
                    {{ teacher.accurate_specialty }}
                  </li>
                  <li>{{ $t("dep") }}: {{ teacher.depatrment.name }}</li>
                </ul>
              </b-col>
              <b-col sm="12" md="8">
                <div class="tbl-header">
                  <table cellpadding="0" cellspacing="0" border="0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{{ $t("researchTitle") }}</th>
                        <th>{{ $t("date") }}</th>
                        <th>{{ $t("magazin2") }}</th>
                        <th>{{ $t("researchLink") }}</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div class="tbl-content">
                  <table cellpadding="0" cellspacing="0" border="0">
                    <tbody>
                      <tr
                        v-for="(research, index) in teacher.research"
                        :key="research.id"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ research.title }}</td>
                        <td>{{ research.publish_date }}</td>
                        <td>{{ research.magazine }}</td>
                        <td>
                          <a :href="research.link"
                            ><i class="fas fa-link"></i
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </section>
    </main> -->