var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lecturtes"},[_c('header',[_c('topbar'),_c('navbar',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile !== true),expression:"isMobile !== true"}]}),_c('mobile-nav',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}]}),_c('section-header',{attrs:{"text":"Course guide"}})],1),_c('main',[_c('section',{staticClass:"section-stages"},[_c('section',{staticClass:"tracking-body"},[_c('div',{staticClass:"container-center"},[_c('div',{staticClass:"tracking-headertext"},[_vm._v(_vm._s(_vm.$t("Course guide")))])])]),_c('b-container',[_c('b-row',_vm._l((_vm.stages),function(stage){return _c('b-col',{key:stage.id,attrs:{"sm":"12","md":"3"}},[_c('div',{staticClass:"stage",on:{"click":function($event){return _vm.getLectures(stage.id)}}},[_c('div',{staticClass:"stage-name"},[_vm._v(" "+_vm._s(stage.name)+" ")])])])}),1)],1)],1),(_vm.lectures.length !== 0)?_c('section',{staticClass:"section-lectures"},[_c('b-container',[_c('b-row',{staticClass:"mt-5"},_vm._l((_vm.lectures),function(lecture){return _c('b-col',{key:lecture.id,staticClass:"mb-5",attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"lecture",on:{"click":function($event){return _vm.getFiles(
                  lecture.id,
                  lecture.teacher.name,
                  lecture.teacher.id,
                  lecture.celbas
                )}}},[_c('div',{staticClass:"lecture-name"},[_vm._v(" "+_vm._s(lecture.name)+" ")])])])}),1)],1)],1):_vm._e(),(_vm.files.length !== 0)?_c('section',{staticClass:"section-files"},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"file",staticStyle:{"transition":"all 0.3s","color":"#fff !important"}},[_vm._v(" "+_vm._s(_vm.$t("lecturer"))+" : "),_c('router-link',{attrs:{"to":{
                  name: 'staff-profile',
                  params: { id: _vm.lecturer_id },
                }}},[_vm._v(" "+_vm._s(_vm.lecturer)+" ")])],1)]),_c('b-col',{staticClass:"mb-3",attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"file",staticStyle:{"transition":"all 0.3s","color":"#fff !important"}},[_vm._v(" "+_vm._s(_vm.$t("celbas"))+" : "),_c('a',{attrs:{"href":'https://nuc.edu.iq/Al-Nisour/public/' + _vm.celbas,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("clickhere")))])])]),_vm._l((_vm.files),function(file){return _c('b-col',{key:file.id,staticClass:"mb-3",attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"file"},[_c('a',{attrs:{"href":'https://nuc.edu.iq/Al-Nisour/public/' + file.file,"target":"_blank"}},[_vm._v(_vm._s(file.name))])])])})],2)],1)],1):_vm._e()]),_c('footer',[_c('footer-component')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }