<template>
  <div>
  <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <video-bg />
    </header>
    <main>
      <section-challenge />
      <section-scholarship/>
      <section-what-to-study />
      <section-join-us />
      <section-statics />
      <section-gallery />
      <section-contact />
      <section-commitments />
      <section-news />
      <section-our-partinars />
      <!-- <section-feeds /> -->
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
  
</template>

<script>
// import { filesUrl } from "@/main";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
import VideoBg from "@/components/home/VideoBg.component.vue";
import SectionChallenge from "@/components/home/SectionChallenge.component.vue";
import SectionWhatToStudy from "@/components/home/SectionWhatToStudy.component.vue";
import SectionJoinUs from "@/components/home/SectionJoinUs.component.vue";
import SectionScholarship from "@/components/home/SectionScholarship.component.vue";
import SectionGallery from "@/components/home/SectionGallery.component.vue";
import SectionStatics from "@/components/home/SectionStatics.component.vue";
import SectionContact from "@/components/home/SectionContact.component.vue";
import SectionCommitments from "@/components/home/SectionCommitments.component.vue";
import SectionNews from "@/components/home/SectionNews.component.vue";
// import SectionFeeds from "@/components/home/SectionFeeds.component.vue";
import SectionOurPartinars from "@/components/home/SectionOurPartinars.component.vue";
export default {
  name: "Home",
  // title() {
  //   if (localStorage.getItem("lang") == "ar") {
  //     return "كلية النسور الجامعة";
  //   } else {
  //     return "Al-Nisour University College";
  //   }
  // },
  components: {
    Topbar,
    Navbar,
    MobileNav,
    VideoBg,
    SectionChallenge,
    SectionWhatToStudy,
    SectionJoinUs,
    SectionStatics,
    SectionScholarship,
    SectionContact,
    SectionCommitments,
    SectionNews,
    SectionOurPartinars,
    // SectionFeeds,
    FooterComponent,
    SectionGallery,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  beforeMount(){
  console.log("beforeMount");
  },
    created:async function()  {
    if(localStorage.getItem("lang")=="en"){
    document.querySelector('meta[name="description"]').setAttribute("content", "Al-Nisour University College is one of the most prestigious private colleges in Iraq. It was opened in 2011 by a decision of the Council of Ministers. The college specializes in the fields of pharmacy, anaesthesia techniques, radiology and ultrasound techniques, computer techniques engineering, pathological analysis techniques, law, business administration, English language, Physical education and sports sciences, and to be of benefit and distinction in higher education and scientific research leading to the development of knowledge, community service, graduating efficient staff, and working to expand horizontally and vertically in specialization");
    document.querySelector('meta[name="title"]').setAttribute("content","Home Page");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "Al-Nisour University College is one of the most prestigious private colleges in Iraq. It was opened in 2011 by a decision of the Council of Ministers. The college specializes in the fields of pharmacy, anaesthesia techniques, radiology and ultrasound techniques, computer techniques engineering, pathological analysis techniques, law, business administration, English language, Physical education and sports sciences, and to be of benefit and distinction in higher education and scientific research leading to the development of knowledge, community service, graduating efficient staff, and working to expand horizontally and vertically in specialization");
    document.querySelector('meta[property="og:title"]').setAttribute("content","Home Page");
    document.title = ` Al-Nisour University College  - Home Page`;
    }else{
    document.querySelector('meta[name="description"]').setAttribute("content", "كلية النسور الجامعة من الكليات الاهلية المرموقة في العراق ، تم افتتاحها في عام ٢٠١١ بموجب قرار مجلس الوزراء ، الكلية متخصصة في حقول الصيدلة ،تقنيات التخدير ،تقنيات الاشعة و السونار ، هندسة تقنيات الحاسوب ، تقنيات التحليلات المرضية ، القانون ، إدارة الأعمال ، اللغة الانكليزية ، التربية البدنية و علوم الرياضة و ان تكون ذات فائدة ومتميزة بالتعليم العالي والبحث العلمي المؤدي إلى تطوير المعرفة وخدمة المجتمع وتخريج الكوادر الكفؤة والعمل على التوسع أفقيا وعموديا في المتخصصة");
    document.querySelector('meta[name="title"]').setAttribute("content","الصفحة الرئيسية");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "كلية النسور الجامعة من الكليات الاهلية المرموقة في العراق ، تم افتتاحها في عام ٢٠١١ بموجب قرار مجلس الوزراء ، الكلية متخصصة في حقول الصيدلة ،تقنيات التخدير ،تقنيات الاشعة و السونار ، هندسة تقنيات الحاسوب ، تقنيات التحليلات المرضية ، القانون ، إدارة الأعمال ، اللغة الانكليزية ، التربية البدنية و علوم الرياضة و ان تكون ذات فائدة ومتميزة بالتعليم العالي والبحث العلمي المؤدي إلى تطوير المعرفة وخدمة المجتمع وتخريج الكوادر الكفؤة والعمل على التوسع أفقيا وعموديا في المتخصصة");
    document.querySelector('meta[property="og:title"]').setAttribute("content","الصفحة الرئيسية");
    document.title = `كلية النسور الجامعة  - الصفحة الرئيسية`;
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    var currentUrl = window.location.pathname;
    document.querySelector('meta[property="og:url"]').setAttribute("content",`${window.location.origin}${currentUrl}`);
    console.log("created");
  },
  mounted() {
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
};
</script>
