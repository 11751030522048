<template>
  <div class="login">
    <!-- <video autoplay muted loop id="myVideo">
      <source src="@/assets/videos/bg.mp4" type="video/mp4" />
    </video> -->
    <img src="../../assets/images/bg.jpeg" alt="" />
    <div class="overlay"></div>
    <div class="login-form">
      <h2>مرحبا بكم في كلية النسور الجامعة</h2>
      <p>لتسجيل الدخول يرجى مليء الحقول الاتية</p>
    <GoogleLogin :params="params" :renderParams="renderParams" :onSuccess="onSuccess" :onFailure="onFailure"></GoogleLogin>

      <b-form>

        <b-form-group
          id="input-group-1"
          label="البريد الالكتروني:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.email"
            type="email"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="الرقم السري:"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="form.password"
            type="password"
            required
          ></b-form-input>
        </b-form-group>
        <div class="form-controls">
          <a href="#" @click="login">
            <button-fill text="تسجيل دخول" />
          </a>
          <span>ليس لديك حساب؟</span>
          <router-link :to="{ name: 'register' }">انشاء حساب</router-link>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
import GoogleLogin from 'vue-google-login';
export default {
  title() {
    if (localStorage.getItem("lang") == "ar") {
      return "كلية النسور الجامعة";
    } else {
      return "Al-Nisour University College";
    }
  },
  components: {
    ButtonFill,
    GoogleLogin
  },
  data() {
    return {
       params: {
                    client_id: "174742659541-uri6gfao0fm537vka21e9nlfgr1botkd.apps.googleusercontent.com"//Al-Nisour web
                },
                // only needed if you want to render the button with the google ui
                renderParams: {
                    width: 250,
                    height: 50,
                    longtitle: true
                },
      form: {
        full_name: null,
        phone: null,
        email: null,
        password: null,
        image:null,
        type:null
      },
      google_form: {
        full_name: null,
        phone: "00000000000",
        email: null,
        password: null,
        image_url:null,
        type:null
      },
    };
  },
  methods: {
    login() {
      if (this.form.email && this.form.password) {
        this.axios
          .post("auth/login", this.form)
          .then((res) => {
            console.log(res);
            localStorage.setItem("NisourToken", res.data.message.token);
            this.$router.push("/");
          })
          .catch(() => {
            this.$swal("يرجى التاكد من مليء الحقول بشكل صحيح", "", "warning");
          });
      } else {
        this.$swal("يرجى مليء جميع الحقول", "", "warning");
      }
    },
    loginByGoogle(password,image,name,email) {
      this.google_form.email =email
      this.google_form.password =password
      this.google_form.image_url = image
      this.google_form.full_name = name
      this.google_form.type = "google"
      if (this.google_form.email && this.google_form.password) {
        this.axios
          .post("auth/login", this.google_form)
          .then((res) => {
            console.log(res);
            localStorage.setItem("NisourToken", res.data.message.token);
            this.$router.push("/");
          })
          .catch((err) => {
            if (err.response.data.email) {
              this.$swal(
                "البريد الالكتروني مستخدم يرجى التاكد من المعلومات",
                "",
                "warning"
              );
            } else if (err.response.data.phone) {
              this.$swal(
                "يرجى التاكد من رقم الهاتف",
                "رقم الهاتف غير صحيح",
                "warning"
              );
            } else {
              this.$swal("يرجى التاكد من مليء الحقول بشكل صحيح", "", "warning");
            }
          });
      } else {
        this.$swal("يرجى مليء جميع الحقول", "", "warning");
      }
    },
    onSuccess(googleUser) {
      console.log(googleUser.getBasicProfile())
            this.loginByGoogle(googleUser.getBasicProfile().FW,googleUser.getBasicProfile().eN,
            googleUser.getBasicProfile().tf,googleUser.getBasicProfile().tv);
        }
  },
};
</script>
