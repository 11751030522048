var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dep-info research"},[_c('header',[_c('topbar'),_c('navbar',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile !== true),expression:"isMobile !== true"}]}),_c('mobile-nav',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}]}),_c('section-header',{attrs:{"text":"depResearches"}})],1),_c('main',[_c('section',{staticClass:"section-deps"},[_c('b-container',[_c('section',{staticClass:"section section-lessons",attrs:{"data-aos":"fade-left"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("depResearches"))+" ")]),_c('div',[_c('div',{staticClass:"tbl-header"},[_c('table',{attrs:{"cellpadding":"0","cellspacing":"0","border":"0"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("researchTitle")))]),_c('th',[_vm._v(_vm._s(_vm.$t("teacher")))]),_c('th',[_vm._v(_vm._s(_vm.$t("dep")))]),_c('th',[_vm._v(_vm._s(_vm.$t("date")))]),_c('th',[_vm._v(_vm._s(_vm.$t("magazin2")))]),_c('th',[_vm._v(_vm._s(_vm.$t("researchLink")))])])])])]),_c('div',{staticClass:"tbl-content"},[_c('table',{attrs:{"cellpadding":"0","cellspacing":"0","border":"0"}},[_c('tbody',_vm._l((_vm.installments),function(research,index){return _c('tr',{key:research.id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(research.title))]),_c('td',[_c('router-link',{attrs:{"to":{
                          name: 'staff-profile',
                          params: { id: research.teacher.id },
                        }}},[_vm._v(" "+_vm._s(research.teacher.name)+" ")])],1),_c('td',[_c('router-link',{attrs:{"to":{
                          name: 'about-dep',
                          params: { id: research.department.id },
                        }}},[_vm._v(" "+_vm._s(research.department.name)+" ")])],1),_c('td',[_vm._v(_vm._s(research.publish_date))]),_c('td',[_vm._v(_vm._s(research.magazine))]),_c('td',[_c('a',{attrs:{"href":research.link}},[_c('i',{staticClass:"fas fa-link"})])])])}),0)])])])])])],1),_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center mb-5"},[_c('b-pagination',{staticClass:"mx-auto",attrs:{"size":"lg","total-rows":_vm.totalCount,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1),_c('footer',[_c('footer-component')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }