<template>
  <div class="application">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="gallery" />
    </header>
    <main>
      <section class="section-details">
        <b-container>
          <b-row>
            <b-col
              class="mb-5"
              sm="12"
              md="4"
              v-for="(image, i) in images"
              :key="image.id"
            >
              <iframe
                v-if="image.video_url"
                :src="youTubeLink(image.video_url)"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <img
                :src="
                  'https://nuc.edu.iq/Al-Nisour/public/' +
                  image.image
                "
                alt=""
                v-if="image.image"
                @click="index = i"
              />
            </b-col>
          </b-row>
        </b-container>
      </section>
      <vue-gallery-slideshow
        :images="imageLinks"
        :index="index"
        @close="index = null"
      ></vue-gallery-slideshow>
      <b-container>
        <b-row>
          <b-col class="d-flex justify-content-center mb-5">
            <b-pagination
              size="lg"
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              aria-controls="my-table"
              class="mx-auto"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import VueGallerySlideshow from "vue-gallery-slideshow";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
// import ButtonFill from "@/components/layout/ButtonFill.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    // ButtonFill,
    MobileNav,
    VueGallerySlideshow,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalCount: "",
      isMobile: false,
      images: [],
      index: null,
      imageLinks: [],
    };
  },
  watch: {
    currentPage: {
      handler: function () {
        this.getImages();
      },
    },
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University College  - gallery`;
    document.querySelector('meta[name="title"]').setAttribute("content","gallery");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","gallery");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }else{
    document.title = `كلية النسور الجامعة  - معرض الصور`;
    document.querySelector('meta[name="title"]').setAttribute("content","معرض الصور");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","معرض الصور");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    this.getImages();
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
  methods: {
    getImages() {
      let skip = this.currentPage - 1;
      this.axios.get(`gallery/clint?skip=${skip}&take=10`).then((res) => {
        console.log(res)
        res.data.items.forEach((item) => {
          if (item.video_url) {
            this.images.push(item);
          }
        });
        res.data.items.forEach((item) => {
          if (item.image) {
            this.images.push(item);
          }
        });
        this.totalCount = res.data.totalCount;
        this.images.forEach((image) => {
          if (image.image) {
            this.imageLinks.push(
              `https://nuc.edu.iq/Al-Nisour/public/${image.image}`
            );
          }
        });
      });
    },
    youTubeLink(link) {
      let youtube = link.split(/=(?=[^.]+$)/);
      return `https://www.youtube.com/embed/${youtube[1]}`;
    },
  },
};
</script>

<style scoped>
.button-fill {
  font-size: 80%;
}

img,
iframe {
  width: 100%;
  height: 30rem;
  cursor: pointer;
  transition: all 0.5s;
}

img:hover,
iframe:hover {
  transform: scale3d(1.15, 1.15, 1.15);
}

img {
  object-fit: cover;
}
</style>
