<template>
    <div class="application dep-info research">
      <header>
        <topbar />
        <navbar v-show="isMobile !== true" />
        <mobile-nav v-show="isMobile" />
        <section-header text="College statistics" />
      </header>
      <main>
        <section class="section-details">
          <b-container>
            <h4 class="mb-5">{{ $t("College statistics") }}</h4>
          </b-container>
        </section>

        <section-statics />
        <section class="section-deps">
            <b-row>
                <b-col sm="12" md="6" v-for="dep in statics" :key="dep.id">
        <b-container >
            
                    <section data-aos="fade-left" class="section section-lessons">
            <h3>
              {{ dep.title }}
            </h3>
            <div>
            
              <div class="tbl-header">
                
                <table cellpadding="0" cellspacing="0" border="0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{{ $t("dep") }}</th>
                      <th>{{ $t("terms.morning.title") }}</th>
                      <th>{{ $t("terms.evening.title") }}</th>
                      <th>{{ $t("total") }}</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="tbl-content"  v-for="(s, index) in dep.statistic" :key="s.id">
                <table cellpadding="0" cellspacing="0" border="0">
                  <tbody>
                    <tr >
                      <td>{{ index + 1 }}</td>
                      <td>
                        <router-link
                          :to="{
                            name: 'about-dep',
                            params: { id: dep.id },
                          }"
                        >
                          {{ dep.title }}
                        </router-link>
                      </td>
                      <td>{{ s.morning }}</td>
                      <td>{{ s.evening }}</td>
                      <td>{{ s.total }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="tbl-content">
                <table cellpadding="0" cellspacing="0" border="0">
                  <tbody>
                    <tr >
                      <td></td>
                      <td>
                        {{ $t("total") }}
                      </td>
                      <td>{{ dep.sums.morning }}</td>
                      <td>{{ dep.sums.evening }}</td>
                      <td>{{ dep.sums.total }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          
        </b-container>
        
    </b-col>
            </b-row>
      </section>
      </main>
      <footer>
        <footer-component />
      </footer>
    </div>
  </template>
  
  <script>
  // import { filesUrl } from "@/main";
  import MobileNav from "@/components/layout/MobileNav.component.vue";
  import Topbar from "@/components/layout/Topbar.component.vue";
  import Navbar from "@/components/layout/Navbar.component.vue";
  import SectionStatics from "@/components/home/SectionStatics.component.vue";
  import SectionHeader from "@/components/layout/SectionHeader.component.vue";
  import FooterComponent from "@/components/layout/FooterComponent.component.vue";
  export default {
    components: {
      Topbar,
      Navbar,SectionStatics,
      SectionHeader,
      FooterComponent,
      MobileNav,
    },
    data() {
      return {
        name: "",
        path: "lib/pdfjs/web/viewer.html",
        isMobile: false,
        statics:[]
      };
    },
    mounted() {
        this.getStatics()
      if(localStorage.getItem("lang")=="en"){
      document.title = ` Al-Nisour University College  - five-year plan`;
      document.querySelector('meta[name="title"]').setAttribute("content","five-year plan");
      document.querySelector('meta[name="description"]').setAttribute("content", "Planning in general and strategic planning in particular is considered one of the most important functions of modern management, as well as being a basic pillar of other administrative functions. Therefore, the strategic plan aims to clarify the society’s priorities for their expression. It also possesses tools and logical models that ensure their comprehensiveness, consistency, applicability and effectiveness in achieving the desired goals, and this depends on The quality of the administrative skills available to the college, and Al-Nisour College has responded to the ministry’s requirement, which stipulates building a strategic plan (2021-2026) through which long-term goals are set to reach the college’s ambition by implementing its mission.");
      document.querySelector('meta[property="og:title"]').setAttribute("content","five-year plan");
      document.querySelector('meta[property="og:description"]').setAttribute("content", "Planning in general and strategic planning in particular is considered one of the most important functions of modern management, as well as being a basic pillar of other administrative functions. Therefore, the strategic plan aims to clarify the society’s priorities for their expression. It also possesses tools and logical models that ensure their comprehensiveness, consistency, applicability and effectiveness in achieving the desired goals, and this depends on The quality of the administrative skills available to the college, and Al-Nisour College has responded to the ministry’s requirement, which stipulates building a strategic plan (2021-2026) through which long-term goals are set to reach the college’s ambition by implementing its mission.");
      }else{
      document.title = `كلية النسور الجامعة  - الخطة الخمسية`;
      document.querySelector('meta[name="title"]').setAttribute("content","الخطة الخمسية");
      document.querySelector('meta[name="description"]').setAttribute("content", "يعتبر التخطيط بصفة عامة والتخطيط الاستراتيجي بصفة خاصة احد أهم وظائف الادارة الحديثة فضلا عن كونه ركيزة أساسية للوظائف الادارية الاخرى ولذلك تستهدف الخطة الاستراتيجية توضيح أولويات المجتمع التعبير عنها كما تمتلك الادوات والنماذج المنطقية التي تضمن شمولها وتناسقها وقابليتها للتطبيق وفاعليتها في تحقيق الاهداف المرجوة، ويتوقف ذلك على جودة المهارات الادارية المتوفرة لدى الكلية، وقد استجابت كلية النسور لمتطلب الوزارة الذي ينص على بناء خطة استراتيجية (٢٠٢١ - ٢٠٢٦)يتم من خلالها وضع اهداف طويلة الامد توصل الكلية الي طموحها من خلال تنفيذ رسالتها");
      document.querySelector('meta[property="og:title"]').setAttribute("content","الخطة الخمسية");
      document.querySelector('meta[property="og:description"]').setAttribute("content", "يعتبر التخطيط بصفة عامة والتخطيط الاستراتيجي بصفة خاصة احد أهم وظائف الادارة الحديثة فضلا عن كونه ركيزة أساسية للوظائف الادارية الاخرى ولذلك تستهدف الخطة الاستراتيجية توضيح أولويات المجتمع التعبير عنها كما تمتلك الادوات والنماذج المنطقية التي تضمن شمولها وتناسقها وقابليتها للتطبيق وفاعليتها في تحقيق الاهداف المرجوة، ويتوقف ذلك على جودة المهارات الادارية المتوفرة لدى الكلية، وقد استجابت كلية النسور لمتطلب الوزارة الذي ينص على بناء خطة استراتيجية (٢٠٢١ - ٢٠٢٦)يتم من خلالها وضع اهداف طويلة الامد توصل الكلية الي طموحها من خلال تنفيذ رسالتها");
      }
      document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
      // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
      if (window.innerWidth > 991) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
      
    },
    methods: {
    getStatics() {
      this.axios.get(`department/Statistic/clint`).then((res) => {
        this.statics = res.data.items;
      });
    },
  },
  };
  </script>
  
  <style scoped>
  .button-fill {
    font-size: 80%;
  }
  </style>
  