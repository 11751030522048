<template>
  <div class="courses  application">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="susCourses" />
    </header>
    <main>
      <section class="section-courses">
        <b-container>
          <b-row v-if="courses">
            <b-col
              sm="12"
              md="4"
              class="mb-5"
              v-for="(course, index) in courses"
              :key="course.id"
            >
              <div
                class="courses-card"
                data-aos="fade"
                :data-aos-delay="`${index + 5}00`"
              >
                <div class="image-placeholder">
                  <img :src="getCourseImage(course.image)" alt="" />
                </div>
                <div class="content">
                  <h3>
                    <router-link
                      :to="{
                        name: 'course-profile',
                        params: { id: course.id },
                      }"
                      >{{ shortenTitle(course.name) }}
                    </router-link>
                  </h3>
                  <p>
                    {{ shortenText(course.description) }}
                  </p>
                  <div class="price">
                    <p v-if="course.price !== '0'">{{ course.price }}</p>
                    <!-- <p v-else>{{ $t("general.free") }}</p> -->
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <b-container>
        <b-row>
          <b-col class="d-flex justify-content-center mb-5">
            <b-pagination
              size="lg"
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              aria-controls="my-table"
              class="mx-auto"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-container>
      <section class="sec">
        <b-container>
          <a class="links-button"
            :href="'Sustainable-Meeting'" 
            target="_blank"
          >
            <button-fill text="sus.sus29" />
          </a>
          <a class="links-button"
           :href="'Sustainable-Investment-Policy'" 
            target="_blank"
          >
            <button-fill text="sus.sus1" />
          </a>
          <a class="links-button"
            :href="`Sustainable-procurement-procedures`"
            target="_blank"
          >
            <button-fill text="sus.sus3" />
          </a>
          <a class="links-button"
            :href="`continuse-development`"
            target="_blank"
          >
            <button-fill text="sustainable" />
          </a>
          <a class="links-button"
           :href="'Sustainability-Donations-Funding'" 
            target="_blank"
          >
            <button-fill text="sus.sus64" />
          </a>
          <a class="links-button"
           :href="'Sustainability-Researches'" 
            target="_blank"
          >
            <button-fill text="susResearches" />
          </a>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main.js";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
    ButtonFill
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      courses: null,
      isMobile: false,
      totalCount: "",
    };
  },
  watch: {
    currentPage: {
      handler: function () {
        this.getCourses();
      },
    },
  },
  computed: {
    rows() {
      return this.courses.length;
    },
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University College  - sustainable development seminars`;
    document.querySelector('meta[name="title"]').setAttribute("content","sustainable development seminars");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","sustainable development seminars");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }else{
    document.title = `كلية النسور الجامعة  - ندوات التنمية المستدامة`;
    document.querySelector('meta[name="title"]').setAttribute("content","ندوات التنمية المستدامة");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","ندوات التنمية المستدامة");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getCourses();
  },
  methods: {
    getCourses() {
      let skip = this.currentPage - 1;
      this.axios
        .get(`course/sustainablity/clint?skip=${skip}&take=${this.perPage}`)
        .then((res) => {
          this.totalCount = res.data.totalCount;
          this.courses = res.data.items
        });
    },
    getCourseImage(path) {
      return ` https://www.nuc.edu.iq/backend/public/${path}`;
    },
    shortenText(text) {
      if (text.length > 100) {
        let maxLength = 100;

        let trimmedString = text.substr(0, maxLength);
        return trimmedString + "...";
      } else {
        return text;
      }
    },
    shortenTitle(text) {
      if (text.length > 50) {
        let maxLength = 50;

        let trimmedString = text.substr(0, maxLength);
        return trimmedString + "...";
      } else {
        return text;
      }
    },
  },
};
</script>
