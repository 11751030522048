<template>
  <div class="blog-post blog organazation">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="agreements" />
    </header>
    <main>
      <b-container class="mt-5">
        <b-row>
          <b-col sm="12" md="12" class="p-0">
            <b-row
              class="px-md-5 align-items-stretch"
              v-if="posts.length !== 0"
            >
              <b-col
                sm="12"
                md="4"
                class="mb-5"
                v-for="(post, index) in posts"
                :key="post.id"
              >
                <div
                  class="blog-card"
                  data-aos="fade"
                  :data-aos-delay="`${index + 5}00`"
                >
                  <div class="image-placeholder">
                    <img :src="getImage(post.image)" alt="" />
                  </div>
                  <div class="content">
                    <h3>
                      <router-link
                        :to="{
                          name: 'agreement-post',
                          params: { id: post.id },
                        }"
                        >{{ shortenTitle(post.title) }}
                      </router-link>
                    </h3>
                    <p>
                      {{ shortenText(post.sub_description) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <!-- <b-col sm="12" md="3" class="p-0 pl-md-5">
            <blog-sidebar :posts="posts" :popularPosts="popularPosts" />
          </b-col> -->
        </b-row>
        <b-row>
          <b-col sm="12" md="12" class="pt-5">
            <b-container>
              <b-row>
                <b-col class="d-flex justify-content-center mb-5">
                  <b-pagination
                    size="lg"
                    v-model="currentPage"
                    :total-rows="totalCount"
                    :per-page="perPage"
                    aria-controls="my-table"
                    class="mx-auto"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
          <b-col sm="12" md="3"></b-col>
        </b-row>
      </b-container>
            <section class="section-deps">
        <b-container>
          <section class="section section-links">
            <h3>{{ $t("Additionallinks") }}</h3>
            <ul class="links">
              <li>
                <router-link
                  :to="{ name: 'agreements'}"
                >
                  {{ $t("agreements") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'organazation'}"
                >
                  {{ $t("organazationChart") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'management'}"
                >
                  {{ $t("Collegeadministration") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'calender'}"
                >
                  {{ $t("aboutUs.about17") }}
                </router-link>
              </li>
            </ul>
          </section>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
// import BlogSidebar from "@/components/blog/BlogSidebar.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    // BlogSidebar,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      posts: [],
      popularPosts: [],
      isMobile: false,
      lang: null,
      totalCount: "",
    };
  },
  watch: {
    currentPage: {
      handler: function () {
        this.getAllPosts();
      },
    },
  },
  computed: {
    rows() {
      return this.posts.length;
    },
  },
  async mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.querySelector('meta[name="title"]').setAttribute("content","College Agreements");
    document.querySelector('meta[name="description"]').setAttribute("content", "Al-Nisour University College has held many memoranda of understanding and official exchange agreements with many universities, training centers and various bodies");
    document.querySelector('property="og:title"]').setAttribute("content","College Agreements");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "Al-Nisour University College has held many memoranda of understanding and official exchange agreements with many universities, training centers and various bodies");
    document.title = ` Al-Nisour University College  - College Agreements`;
    }else{
    document.querySelector('meta[name="title"]').setAttribute("content","اتفاقيات الكلية");
    document.querySelector('meta[name="description"]').setAttribute("content", "عقدت كلية النسور الجامعة  العديد من مذكرات التفاهم واتفاقيات التبادل الرسمية مع العديد من الجامعات والمراكز التدريبية والهيئات المختلفة");
    document.querySelector('meta[property="og:title"]').setAttribute("content","اتفاقيات الكلية");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "عقدت كلية النسور الجامعة  العديد من مذكرات التفاهم واتفاقيات التبادل الرسمية مع العديد من الجامعات والمراكز التدريبية والهيئات المختلفة");
    document.title = `كلية النسور الجامعة  - اتفاقيات الكلية`;
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 

    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getAllPosts();
    this.getPopularPosts();
  },
  methods: {
    getAllPosts() {
      let skip = this.currentPage - 1;
      this.axios
        .get(`agreements/clint?skip=${skip}&take=${this.perPage}`)
        .then((res) => {
          this.posts = res.data.items;
          this.totalCount = res.data.totalCount;
        });
    },
    getPopularPosts() {
      this.axios.get("news/list/viewSort?skip=0&take=3").then((res) => {
        this.popularPosts = res.data.items;
      });
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    shortenTitle(text) {
      if (text !== null) {
        if (text.length > 50) {
          let maxLength = 50;
          let trimmedString = text.substr(0, maxLength);
          return trimmedString + " ...";
        } else {
          return text;
        }
      } else {
        return "";
      }
    },
    shortenText(text) {
      if (text !== null) {
        if (text.length > 100) {
          let maxLength = 100;
          let trimmedString = text.substr(0, maxLength);
          return trimmedString + " ...";
        } else {
          return text;
        }
      } else {
        return "";
      }
    },
  },
};
</script>
