<template>
  <div class="post-sidebar">
    <div class="post-sidebar__popular-posts">
      <h3>{{ $t("PopularPosts") }}</h3>
      <ul>
        <li v-for="(post, index) in popularPosts" :key="post.id">
          <div
            class="d-flex align-items-center"
            data-aos="fade-left"
            :data-aos-delay="`${index + 5}00`"
          >
            <router-link :to="{ name: 'blog-post', params: { id: post.id } }">
              <img :src="getImage(post.image)" alt="" />
            </router-link>
            <div class="post-info">
              <p>{{ shortenDate(post.created_at) }}</p>
              <router-link :to="{ name: 'blog-post', params: { id: post.id } }">
                <h4>{{ shortenText(post.title) }}</h4>
              </router-link>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="post-sidebar__recent-posts">
      <h3>{{ $t("Latestposts") }}</h3>
      <ul>
        <li v-for="(post, index) in filteredPosts" :key="post.id">
          <div
            class="d-flex align-items-center"
            data-aos="fade-left"
            :data-aos-delay="`${index + 5}00`"
          >
            <router-link :to="{ name: 'blog-post', params: { id: post.id } }">
              <img :src="getImage(post.image)" alt="" />
            </router-link>
            <div class="post-info">
              <p>{{ shortenDate(post.created_at) }}</p>
              <router-link :to="{ name: 'blog-post', params: { id: post.id } }">
                <h4>{{ shortenText(post.title) }}</h4>
              </router-link>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- <div class="post-sidebar__categories">
      <h3>اشهر الاقسام</h3>
      <ul>
        <li>تكنولوجيا</li>
        <li>ادارة الاعمال</li>
        <li>روبوتات</li>
        <li>حسابات</li>
      </ul>
    </div> -->
  </div>
</template>

<script>
import { filesUrl } from "@/main";
export default {
  props: ["posts", "popularPosts"],
  computed: {
    filteredPosts: function () {
      return this.posts.slice(0, 4);
    },
  },
  methods: {
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    shortenDate(date) {
      let newDate = date.split("");
      newDate = newDate.slice(0, 10);
      newDate = newDate.join("");
      return newDate;
    },
    shortenText(text) {
      if (text !== null) {
        if (text.length > 50) {
          let maxLength = 50;
          let trimmedString = text.substr(0, maxLength);
          return trimmedString + " ...";
        } else {
          return text;
        }
      } else {
        return "";
      }
    },
  },
};
</script>
