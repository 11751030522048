import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// blog
import Blog from "@/views/blog/Blog.vue";
import BlogByTag from "@/views/blog/BlogByTag.vue"
import BlogPost from "@/views/blog/BlogPost.vue";
// about Us
import Fundation from "../views/AboutUs/Fundation.vue";
import Agreements from "../views/AboutUs/Agreements.vue";
import AgreementPost from "../views/AboutUs/AgreementPost.vue";
import Organazation from "../views/AboutUs/Organazation.vue";
import calender from "../views/AboutUs/calender.vue";
import CollageManagement from "../views/AboutUs/CollageManagement.vue";
// managments
import Council from "@/views/ManagmentDeps/Council.vue";
import Tech from "@/views/ManagmentDeps/Tech.vue";
import Quality from "@/views/ManagmentDeps/Quality.vue";
import Learning from "@/views/ManagmentDeps/Learning.vue";
import Registration from "@/views/ManagmentDeps/Registration.vue";
import Planing from "@/views/ManagmentDeps/Planing.vue";
// science deps
import DepsStaff from "@/views/ScienceDeps/DepsStaff.vue";
import DepInfo from "@/views/ScienceDeps/DepInfo.vue";
import StaffProfile from "@/views/ScienceDeps/StaffProfile.vue";
import StudentsResearch from "@/views/ScienceDeps/StudentsResearches.vue";
import DepNews from "@/views/ScienceDeps/DepNews.vue"
import table from "@/views/ScienceDeps/table.vue"
// jobs
import Jobs from "@/views/jobs/Jobs.vue";
import Job from "@/views/jobs/Job.vue";
import JobApplication from "@/views/jobs/JobApplication.vue";
// courses
import Courses from "@/views/courses/Courses.vue";
import CourseProfile from "@/views/courses/CourseProfile.vue";
// Application
import ApplicationSteps from "@/views/Application/ApplicationSteps.vue";
import ApplicationTerms from "@/views/Application/ApplicationTerms.vue";
import ApplicationTransfer from "@/views/Application/ApplicationTransfer.vue";
import Admission from "@/views/Application/Admission.vue";
// Contact Us
import ContactUs from "@/views/contact/ContactUs.vue";
// FAQ
import FAQ from "@/views/faq/faq.vue";
// Access
import Login from "@/views/access/login.vue";
import Register from "@/views/access/register.vue";
import Profile from "@/views/access/profile.vue";
// Other
import FifthPlan from "@/views/other/FifthPlan.vue";
import Installment from "@/views/other/Installment.vue";
import DeanWords from "@/views/other/DeanWords.vue";
import PageNotFound from "@/views/other/404.vue";
import Gallery from "@/views/other/Gallery.vue";
import relatedlink from "@/views/other/relatedlink.vue";
import Statics from "@/views/other/Statics.vue";
import track from "@/views/other/tra.vue";
import CollageResearches from "@/views/other/CollageResearches.vue";
import depResearches from "@/views/ScienceDeps/depResearches.vue";
import Alumni from "@/views/other/Alumni.vue";
import stdGraduated from "@/views/other/stdGraduated.vue";
//energe
import energyManagementSystem from "@/views/enargy/energyManagementSystem.vue";
import ISO50001 from "@/views/enargy/ISO50001.vue";
import teamEnargy from "@/views/enargy/teamEnargy.vue";
// sustainabilty
import susInves from "@/views/sustainabilty/susInves.vue";
import suspro from "@/views/sustainabilty/suspro.vue";
import susmeet from "@/views/sustainabilty/GovMeeting.vue";
import susdonationsfunding from "@/views/sustainabilty/susdonationsfunding.vue";
import susResearches from "@/views/sustainabilty/susResearches.vue";
import Development from "@/views/sustainabilty/ContinuesDevelopment.vue";
import susCourse from "@/views/sustainabilty/susCourse.vue";
// Lectures
import Lectures from "@/views/lectures/Lectures.vue";

//energy
import energyWorkshops from "@/views/enargy/energyWorkshops.vue";
import energyResearches from "@/views/enargy/energyResearches.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: (to, from, next) => {
      try {
        if (localStorage.getItem("NisourToken")) {
          next("/");
        } else {
          next();
        }
      } catch {
        next();
      }
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    beforeEnter: (to, from, next) => {
      try {
        if (localStorage.getItem("NisourToken")) {
          next("/");
        } else {
          next();
        }
      } catch {
        next();
      }
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
  {
    path: "/blog",
    name: "blog",
    component: Blog,
  },
  {
    path: "/blog/:params",
    name: "blog",
    component: Blog,
  },
  {
    path: "/blog/tag/:tag_id",
    name: "news-by-tag",
    component: BlogByTag,
  },
  {
    path: "/blog/search/:search",
    name: "blog-search",
    component: Blog,
  },
  {
    path: "/blog/:id",
    name: "blog-post",
    component: BlogPost,
  },
  {
    path: "/students-research/:id",
    name: "students-research",
    component: StudentsResearch,
  },
  {
    path: "/about/fundation",
    name: "fundation",
    component: Fundation,
  },
  {
    path: "/about/agreements",
    name: "agreements",
    component: Agreements,
  },
  {
    path: "/about/agreement/:id",
    name: "agreement-post",
    component: AgreementPost,
  },
  {
    path: "/about/organazation",
    name: "organazation",
    component: Organazation,
  },
  {
    path: "/about/calender",
    name: "calender",
    component: calender,
  },
  {
    path: "/managments/tech",
    name: "tech",
    component: Tech,
  },
  {
    path: "/managments/council",
    name: "council",
    component: Council,
  },
  {
    path: "/managments/quality",
    name: "quality",
    component: Quality,
  },
  {
    path: "/managments/learning",
    name: "learning",
    component: Learning,
  },
  {
    path: "/managments/reg",
    name: "reg",
    component: Registration,
  },
  {
    path: "/managments/planing",
    name: "planing",
    component: Planing,
  },
  {
    path: "/about/management",
    name: "management",
    component: CollageManagement,
  },
  {
    path: "/science-deps/staff/:id",
    name: "staff",
    component: DepsStaff,
  },
  {
    path: "/science-deps/about/:id",
    name: "about-dep",
    component: DepInfo,
  },
  {
    path: "/science-deps/table/:id",
    name: "table",
    component: table,
  },
  {
    path: "/science-deps/news/:id",
    name: "dep-news",
    component: DepNews,
  },
  {
    path: "/science-deps/staff/profile/:id",
    name: "staff-profile",
    component: StaffProfile,
  },
  {
    path: "/jobs",
    name: "jobs",
    component: Jobs,
  },
  {
    path: "/job/:id",
    name: "job",
    component: Job,
  },
  {
    path: "/jobs/apply",
    name: "job-application",
    component: JobApplication,
  },
  {
    path: "/courses",
    name: "courses",
    component: Courses,
  },
  {
    path: "/courses/:id",
    name: "course-profile",
    component: CourseProfile,
  },
  {
    path: "/application/steps",
    name: "application-steps",
    component: ApplicationSteps,
  },
  {
    path: "/application/terms",
    name: "application-terms",
    component: ApplicationTerms,
  },
  {
    path: "/application/transfer",
    name: "application-transfer",
    component: ApplicationTransfer,
  },
  {
    path: "/application/admission",
    name: "admission-plan",
    component: Admission,
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
  },
  {
    path: "/application/steps",
    name: "application-steps",
    component: ApplicationSteps,
  },
  {
    path: "/application/terms",
    name: "application-terms",
    component: ApplicationTerms,
  },
  {
    path: "/application/transfer",
    name: "application-transfer",
    component: ApplicationTransfer,
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ,
  },
  {
    path: "/fifth-plan",
    name: "fifth-plan",
    component: FifthPlan,
  },
  {
    path: "/continuse-development",
    name: "continuse-development",
    component: Development,
  },
  {
    path: "/installment",
    name: "installment",
    component: Installment,
  },
  {
    path: "/dean-words",
    name: "dean-words",
    component: DeanWords,
  },
  {
    path: "/science-deps/lectures/:id",
    name: "lectures",
    component: Lectures,
  },
  {
    path: "/gallery",
    name: "gallery",
    component: Gallery,
  },
  {
    path: "/students-graduated/:id",
    name: "students-graduated",
    component: stdGraduated,
  },
  {
    path: "/Sustainable-Investment-Policy",
    name: "SustainableInvestmentPolicy",
    component: susInves,
  },
  {
    path: "/Sustainable-procurement-procedures",
    name: "SustainableProcurementProcedures",
    component: suspro,
  },
  {
    path: "/Sustainable-Meeting",
    name: "SustainableMeeting",
    component: susmeet,
  },
  
  {
    path: "/Sustainability-Donations-Funding",
    name: "susdonationsfunding",
    component: susdonationsfunding,
  },
  {
    path: "/Sustainability-Researches",
    name: "susResearches",
    component: susResearches,
  },
  {
    path: "/Sustainability-Courses",
    name: "susCourse",
    component: susCourse,
  },
  {
    path: "/collage-researches/dept/:id",
    name: "dep-researches",
    component: depResearches,
  },
  {
    path: "/collage-researches",
    name: "collage-researches",
    component: CollageResearches,
  },
  {
    path: "/alumni",
    name: "Alumni",
    component: Alumni,
  },
  //new links
  {
    path: "/energy-management-system", 
    name: "energyManagementSystem",
    component: energyManagementSystem,
  },
  {
    path: "/ISO50001",
    name: "ISO50001",
    component: ISO50001,
  },
  {
    path: "/Energy-Team",
    name: "teamEnargy",
    component: teamEnargy,
  },
  {
    path: "/Energy-Workshops",
    name: "EnargyWorkshops",
    component: energyWorkshops,
  },
  {
    path: "/Energy-Researches",
    name: "EnergyResearches",
    component: energyResearches,
  },
  {
    path: "/Statics",
    name: "Statics",
    component: Statics,
  },
  {
    path: "/related-links",
    name: "relatedlink",
    component: relatedlink,
  },
  {
    path: "/track-your-form",
    name: "trackyourform",
    component: track,
  },
  { path: "*/:id", component: PageNotFound }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});
// router.beforeEach((to, from, next) => {
//   // console.log(to.params.en_title);
//   // document.title = `${process.env.VUE_APP_TITLE} - ${to.name}`
//   // if(localStorage.getItem("lang")=="en"){
//   //   document.querySelector('meta[name="title"]').setAttribute("content", `${to.params.en_title}`);
//   //   document.querySelector('meta[name="description"]').setAttribute("content", `${to.params.en_description}`);
//   // }
//   // else{
//   //   document.querySelector('meta[name="title"]').setAttribute("content", `${to.params.ar_title}`);
//   //   document.querySelector('meta[name="description"]').setAttribute("content", `${to.params.ar_description}`);
//   // }
//   next();
// })
export default router;
