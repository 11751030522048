<template>
    <div class="section-join-us" data-aos="fade" style="margin-top: 10rem;">
      <b-container>
        <b-row>
          <b-col sm="12" md="8">
            <div class="d-md-flex align-items-center h-100">
              <p>{{ $t("textscholarship") }}</p>
            </div>
          </b-col>
          <b-col sm="12" md="4">
            <div class="d-md-flex align-items-center justify-content-end h-100">
              <a
                href="https://scholarship.nuc.edu.iq/"
                target="_blank"
              >
                <button-link text="Fill out the application form" />
              </a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  
  <script>
  import ButtonLink from "@/components/layout/ButtonLink.component.vue";
  export default {
    components: { ButtonLink },
  };
  </script>
  