<template>
  <a class="custome-btn">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    {{ $t(text) }}
  </a>
</template>

<script>
export default {
  name: "button-link",
  props: ["text"],
};
</script>
