<template>
  <div class="lecturtes dep-info research">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="table" />
    </header>
    <main>
      <section class="section-stages">
        <b-container>
          <b-row>
            <b-col sm="12" md="3" v-for="stage in stages" :key="stage.id">
              <div class="stage" @click="getTable(stage.id)">
                <div class="stage-name">
                  {{ stage.name }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <section class="section-deps">
        <b-container>
          <section
            v-show="installments.length > 0"
            data-aos="fade-left"
            class="section section-lessons"
          >
            <div>
              <div class="tbl-header">
                <table cellpadding="0" cellspacing="0" border="0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{{ $t("day") }}</th>
                      <th>{{ $t("time") }}</th>
                      <th>{{ $t("stage") }}</th>
                      <th>{{ $t("group") }}</th>
                      <th>{{ $t("lecture") }}</th>
                      <th>{{ $t("type") }}</th>
                      <!-- <th>{{ $t("teacher") }}</th> -->
                      <th>{{ $t("room") }}</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="tbl-content">
                <table cellpadding="0" cellspacing="0" border="0">
                  <tbody>
                    <tr v-for="(item, index) in installments" :key="item.id">
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.day.title }}</td>
                      <td>{{ item.time }}</td>
                      <td>{{ item.stage.title }}</td>
                      <td>
                        {{ item.group.title }}
                      </td>
                      <td v-if="item.lecture">
                      {{ item.lecture.title }}
                      </td>
                      <td v-if="item.type">
                      {{ item.type.title }}
                      </td>
                      <!-- <td v-if="item.lecturer">
                        <router-link
                          :to="{
                            name: 'staff-profile',
                            params: { id: item.lecturer.id },
                          }"
                        >
                          {{ item.lecturer.title }}
                        </router-link>
                      </td>
                      <td v-else>
                      لم يعين
                      </td> -->
                      <td v-if="item.room">
                      {{ item.room.title }}
                      </td>
                      <td v-else>
                      لم يعين
                      </td> 
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </b-container>
      </section>
      <b-container v-show="installments > 0">
        <b-row>
          <b-col class="d-flex justify-content-center mb-5">
            <b-pagination
              size="lg"
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              class="mx-auto"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
import { mapState } from "vuex";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      installments: [],
      totalInstallment: 0,
      stages: [],
      selected: "",
      options: [
        { value: null, text: "Please select some item" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Default Selected Option" },
        { value: "c", text: "This is another option" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      stage_id: "",
      isMobile: false,
      perPage: 1000,
      currentPage: 1,
      totalCount: "",
    };
  },
  computed: { ...mapState(["depId"]) },
  watch: {
    depId: async function () {
      await this.getStages();
    },
    currentPage: {
      handler: function () {
        this.getTable();
      },
    },
  },
  async mounted() {
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getStages();
  },

  //   &group_id=${this.group_id}
  //   &type_id=${this.type_id.id}&day_id=${this.day_id}
  methods: {
    async getStages() {
      await this.axios
        .get(`stage/clint/department/${this.$route.params.id}`)
        .then((res) => {
          console.log(res);
          this.stages = res.data.stages;
        });
    },
    async getTable(id) {
      this.stage_id = id;
      let skip = this.currentPage - 1;
      await this.axios
        .get(
          `tables/client?skip=${skip}&take=${this.perPage}&stage_id=${this.stage_id}&department=${this.$route.params.id}
          `
        )
        .then((res) => {
          console.log(res.data.items)
          this.installments = res.data.items;
          this.totalCount = res.data.totalCount;
          this.getDep();
        });
    },
    getDep() {
      this.axios
        .get(`since/deparment/clint/${this.$route.params.id}`)
        .then((res) => {
          this.changeMeta(res.data);
        });
    },
    changeMeta(item) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", `${item.about.vision}`);
      document
        .querySelector('meta[property="og:description"]')
        .setAttribute("content", `${item.about.vision}`);
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute("content", `${filesUrl}${item.logo}`);
      document
        .getElementById("icon")
        .setAttribute("href", `${filesUrl}${item.logo}`);
      if (localStorage.getItem("lang") == "ar") {
        document.title = `كلية النسور الجامعة  - بحوث ${item.name} `;
        document
          .querySelector('meta[name="title"]')
          .setAttribute("content", `  - بحوث ${item.name}`);
      } else {
        document.title = ` Al-Nisour University College  - ${item.name} Researches`;
        document
          .querySelector('meta[property="og:title"]')
          .setAttribute("content", `${item.name} Researches`);
      }
    },
  },
};
</script>
