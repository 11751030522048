<template>
  <div class="section-statics">
    <b-container>
      <b-row>
        <b-col
          cols="12"
          class="d-flex align-items-start justify-content-center flex-wrap"
        >
          <div class="statics">
            <h3><IOdometer class="iOdometer" :value="7262" /></h3>
            <p>{{ $t("numberofstudents") }}</p>
          </div>
          <div class="statics">
            <h3><IOdometer class="iOdometer" :value="11" /></h3>
            <p>{{ $t("numberofsections") }}</p>
          </div>
          <div class="statics">
            <h3><IOdometer class="iOdometer" :value="139" /></h3>
            <p>{{ $t("teachingstaff") }}</p>
          </div>
          <div class="statics">
            <h3><IOdometer class="iOdometer" :value="1071" /></h3>
            <p>{{ $t("researches") }}</p>
          </div>
        </b-col>
        <b-col
        style="margin: 3rem;"
          cols="12"
          class="d-flex align-items-start justify-content-center flex-wrap"
        >
          <div class="statics">
            <h3><IOdometer class="iOdometer" :value="61" /></h3>
            <p>{{ $t("numberofmangedeps") }}</p>
          </div>
          <div class="statics">
            <h3><IOdometer class="iOdometer" :value="10" /></h3>
            <p>{{ $t("numberofrankings") }}</p>
          </div>
          <div class="statics">
            <h3><IOdometer class="iOdometer" :value="139" /></h3>
            <p>{{ $t("numberofemployees") }}</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import IOdometer from "vue-odometer";
import "odometer/themes/odometer-theme-default.css";
export default {
  components: {
    IOdometer,
  },
  data() {
    return {
      student: 0,
      deps: 0,
      teachers: 0,
      teachersCount: 0,
      depsCount: 0,
      researchesCount: 0,
      researches: 0,
    };
  },
  mounted() {
    this.getTeachersCount();
    this.getdepsCount();
    this.getResearchesCount();
    window.addEventListener("scroll", () => {
      if (window.scrollY > 1624) {
        const that = this;
        setInterval(function () {
          that.student = 5057;
          that.deps = that.depsCount;
          that.teachers = that.teachersCount;
          that.researches = that.researchesCount;
        }, 300);
      }
    });
  },
  methods: {
    getTeachersCount() {
      this.axios.get("teacher/clint?skip=0&take=10").then((res) => {
        this.teachersCount = res.data.totalCount;
      });
    },
    getdepsCount() {
      this.axios.get("since/deparment/clint?skip=0&take=100").then((res) => {
        this.depsCount = res.data.totalCount;
      });
    },
    getResearchesCount() {
      this.axios.get("research/clint?skip=0&take=10").then((res) => {
        this.researchesCount = res.data.totalCount;
        console.log();
      });
    },
  },
};
</script>
