<template>
  <div class="application dep-info">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="sus.sus29" />
    </header>
    <main>
       <section class="section-d" data-aos="fade-up">
        <b-container>
          <h4>{{ $t("sus.sus48") }}</h4>
          <ul class="steps-ul">
            <li>{{ $t("sus.sus49") }}</li>
            <li>{{ $t("sus.sus50") }}</li>
            <li>{{ $t("sus.sus51") }}</li>
          </ul>
        </b-container>
      </section>
       <section class="section-d" data-aos="fade-up">
        <b-container>
          <h4>{{ $t("sus.sus52") }}</h4>
          <ul class="steps-ul">
            <li>{{ $t("sus.sus53") }}</li>
            <li>{{ $t("sus.sus54") }}</li>
            <li>{{ $t("sus.sus55") }}</li>
            <li>{{ $t("sus.sus56") }}</li>
          </ul>
        </b-container>
      </section>
       <section class="section-d" data-aos="fade-up">
        <b-container>
          <h4>{{ $t("sus.sus57") }}</h4>
          <ul class="steps-ul">
            <li>{{ $t("sus.sus14") }}</li>
            <li>{{ $t("sus.sus59") }}</li>
            <li>{{ $t("sus.sus60") }}</li>
            <li>{{ $t("sus.sus61") }}</li>
            <li>{{ $t("sus.sus62") }}</li>
            <li>{{ $t("sus.sus63") }}</li>
          </ul>
        </b-container>
      </section>
      <section class="section-d">
        <b-container>
          <a
            :href="`lib/pdfjs/web/files/govmeet.pdf`"
            target="_blank"
          >
            <button-fill text="readmore1" />
          </a>
        </b-container>
      </section>
      <section class="section-deps">
        <b-container>
      <section class="section section-lessons" data-aos="fade-right">
            <h3>{{ $t("sus.sus29") }}</h3>
            <div>
              <div class="tbl-header">
                <table cellpadding="0" cellspacing="0" border="0">
                  <thead>
                    <tr>
                      <th>{{ $t("sus.sus30") }}</th>
                      <th>{{ $t("sus.sus31") }}</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="tbl-content">
                <table cellpadding="0" cellspacing="0" border="0">
                  <tbody>
                    <tr>
                      <td>{{ $t("sus.sus32") }}</td>
                      <td>{{ $t("sus.sus35") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("sus.sus34") }}</td>
                      <td>{{ $t("sus.sus35") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("sus.sus36") }}</td>
                      <td>{{ $t("sus.sus37") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("sus.sus38") }}</td>
                      <td>{{ $t("sus.sus39") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("sus.sus44") }}</td>
                      <td>{{ $t("sus.sus45") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("sus.sus40") }}</td>
                      <td>{{ $t("sus.sus41") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("sus.sus46") }}</td>
                      <td>{{ $t("sus.sus47") }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("sus.sus42") }}</td>
                      <td>{{ $t("sus.sus43") }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          </b-container>
      </section>
      <section class="section-d">
        <b-container>
          <a class="links-button"
            :href="'Sustainable-Investment-Policy'" 
            target="_blank"
          >
            <button-fill text="sus.sus29" />
          </a>
          <a class="links-button"
           :href="'Sustainable-procurement-procedures'" 
            target="_blank"
          >
            <button-fill text="sus.sus1" />
          </a>
          <a class="links-button"
            :href="`continuse-development`"
            target="_blank"
          >
            <button-fill text="sustainable" />
          </a>
          <a class="links-button"
           :href="'Sustainability-Donations-Funding'" 
            target="_blank"
          >
            <button-fill text="sus.sus64" />
          </a>
          <a class="links-button"
           :href="'Sustainability-Researches'" 
            target="_blank"
          >
            <button-fill text="susResearches" />
          </a>
          
          <a class="links-button"
           :href="'Sustainability-Courses'" 
            target="_blank"
          >
            <button-fill text="susCourses" />
          </a>
        </b-container>
      </section>
      
      <!-- <section class="section section-links">
            <h3>{{ $t("Additionallinks") }}</h3>
            <ul class="links">
              <li>
                <router-link
                  :to="{ name: 'staff' }"
                >
                  {{ $t("teachingstaff") }}
                </router-link>
              </li>
              <li >
                <a >
                  {{ $t("SectionTable") }}
                </a>
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'lectures',
                  }"
                >
                  {{ $t("lectures") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'students-research',
                  }"
                >
                  {{ $t("studentsResearches") }}
                </router-link>
              </li>
              <li >
                <router-link
                  :to="{
                    name: 'dep-news',
                  }"
                >
                  {{ $t("depNews") }}
                </router-link>
              </li>
            </ul>
          </section> -->
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    ButtonFill,
    MobileNav,
  },
  data() {
    return {
      name: "",
      path: "lib/pdfjs/web/viewer.html",
      isMobile: false,
      host: window.location.host,
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University College  - Governance meeting minutes`;
    document.querySelector('meta[name="title"]').setAttribute("content","Governance meeting minutes");
    document.querySelector('meta[name="description"]').setAttribute("content", "The position of governance within Al-Nisour University College");
    document.querySelector('meta[property="og:title"]').setAttribute("content","Governance meeting minutes");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "The position of governance within Al-Nisour University College");
    }else{
    document.title = `كلية النسور الجامعة  - محضر اجتماع الحوكمة`;
    document.querySelector('meta[name="title"]').setAttribute("content","محضر اجتماع الحوكمة");
    document.querySelector('meta[name="description"]').setAttribute("content", "موقف الحوكمة داخل كلية النسور الجامعة");
    document.querySelector('meta[property="og:title"]').setAttribute("content","محضر اجتماع الحوكمة");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "موقف الحوكمة داخل كلية النسور الجامعة");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
  methods: {},
};
</script>

<style scoped>
.button-fill {
  font-size: 80%;
}
</style>
