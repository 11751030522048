<template>
  <div class="application">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="planing" />
    </header>
    <main>
      <section class="section-details" data-aos="fade-up">
        <section class="section-details__manage">
          <b-container>
            <div>
              <p>{{ $t("planingInfo") }}</p>
            </div>
          </b-container>
        </section>
        <section class="section-details__missions">
          <b-container>
            <h4>{{ $t("planingTasks.des") }}</h4>
            <ol>
              <b-row>
                <b-col sm="12" md="6">
                  <div class="pl-5">
                    <li>{{ $t("planingTasks.li1") }}</li>
                    <li>
                      {{ $t("planingTasks.li2") }}
                    </li>
                    <li>
                      {{ $t("planingTasks.li3") }}
                    </li>
                    <li>
                      {{ $t("planingTasks.li4") }}
                    </li>
                  </div>
                </b-col>
                <b-col sm="12" md="6">
                  <div class="pr-5"></div>
                </b-col>
              </b-row>
            </ol>
          </b-container>
        </section>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University College  - Planning and Follow-up Department`;
    document.querySelector('meta[name="title"]').setAttribute("content","Planning and Follow-up Department");
    document.querySelector('meta[name="description"]').setAttribute("content", "The Planning and Follow-up Department was established according to Administrative Order No. (M/112/10 on 1/4/2018) due to the college’s need for a specialized department in setting strategies, drawing goals and future plans, and following up on their implementation.");
    document.querySelector('meta[property="og:title"]').setAttribute("content","Planning and Follow-up Department");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "The Planning and Follow-up Department was established according to Administrative Order No. (M/112/10 on 1/4/2018) due to the college’s need for a specialized department in setting strategies, drawing goals and future plans, and following up on their implementation.");
    }else{
    document.title = `كلية النسور الجامعة  - قسم التخطيط و المتابعة`;
    document.querySelector('meta[name="title"]').setAttribute("content","قسم التخطيط و المتابعة");
    document.querySelector('meta[name="description"]').setAttribute("content", "تأسس قسم التخطيط و المتابعة بموجب الامر الاداري المرقم ( م/112/10 في 2018/1/4 ) لحاجة الكلية  الى قسم متخصص في وضع الاستراتيجيات و رسم الاهداف و الخطط المستقبلية و متابعة تنفيذها");
    document.querySelector('meta[property="og:title"]').setAttribute("content","قسم التخطيط و المتابعة");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "تأسس قسم التخطيط و المتابعة بموجب الامر الاداري المرقم ( م/112/10 في 2018/1/4 ) لحاجة الكلية  الى قسم متخصص في وضع الاستراتيجيات و رسم الاهداف و الخطط المستقبلية و متابعة تنفيذها");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
};
</script>
