<template>
  <a class="button-fill">
    <div class="text">{{ $t(text) }}</div>
    <div class="bg"></div>
  </a>
</template>

<script>
export default {
  name: "button-fill",
  props: ["text"],
};
</script>
