<template>
  <div class="organazation application dep-info">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="admissionPlan" />
    </header>
    <main>
      <b-container>
        <b-row>
          <b-col sm="12">
            <div class="image-container">
              <img src="../../assets/images/organazation.jpeg" alt="" />
              <!-- <img
                src="../../assets/images/blog-post-header.jpg"
                alt=""
                v-else
              /> -->
            </div>
          </b-col>
        </b-row>
      </b-container>
      <section class="sec">
        <b-container>
          <a class="links-button"
            :href="'/application/steps'" 
            target="_blank"
          >
            <button-fill text="RegistrationMechanism" />
          </a>
          <a class="links-button"
           :href="'/application/terms'" 
            target="_blank"
          >
            <button-fill text="AdmissionRequirements" />
          </a>
          <a class="links-button"
            :href="`/application/transfer`"
            target="_blank"
          >
            <button-fill text="ConditionsForTransferringBetweenUniversities" />
          </a>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
    ButtonFill
  },
  data() {
    return {
      isMobile: false,
      lang: "ar",
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University College  - Admission Plan`;
    document.querySelector('meta[name="title"]').setAttribute("content","Admission Plan");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","Admission Plan");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }else{
    document.title = `كلية النسور الجامعة  - خطة القبول`;
    document.querySelector('meta[name="title"]').setAttribute("content","خطة القبول");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","خطة القبول");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    this.lang = localStorage.getItem("lang");
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
};
</script>
