<template>
  <div class="post-comments">
    <div class="post-comments__comment" v-if="isLoggedIn">
      <div class="user-image">
        <img src="https://randomuser.me/api/portraits/women/79.jpg" alt="" />
      </div>
      <div class="comment">
        <textarea
          @keyup.enter="addComment"
          name="comment"
          v-model="comment"
          id="comment"
          cols="30"
          rows="10"
          :placeholder="$t('comment')"
        ></textarea>
      </div>
    </div>
    <div class="not-logged-in" v-if="!isLoggedIn">
      {{ $t("commentLogin.please") }}
      <router-link :to="{ name: 'login' }">{{
        $t("commentLogin.login")
      }}</router-link>
      {{ $t("commentLogin.comment") }}
    </div>
    <div class="comments">
      <div class="user-comment" v-for="comment in comments" :key="comment.id">
        <div class="user-image">
          <img :src="getImage(comment.user.image)" alt="" />
        </div>
        <div class="comment-content">
          <div class="username">{{ comment.user.full_name }}</div>
          <div class="content">
            {{ comment.comment }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
export default {
  props: ["comments"],
  data() {
    return {
      comment: null,
      isLoggedIn: false,
    };
  },
  mounted() {
    if (localStorage.getItem("NisourToken")) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
  },
  methods: {
    addComment() {
      let data = new FormData();
      if (this.comment !== "" || this.comment !== null) {
        data.append("comment", this.comment);
        data.append("news_id", this.$route.params.id);

        this.axios.post("comment", data).then(() => {
          this.getPost();
          this.comment = null;
        });
      }
    },
    getPost() {
      this.axios.get(`news/clint/${this.$route.params.id}`).then((res) => {
        this.post = res.data;
        this.comments = res.data.comment.reverse();
      });
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
  },
};
</script>
