<template>
  <div class="jobs">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="Availablejobs" />
    </header>
    <main>
      <section class="section-jobs">
        <b-container>
          <b-row v-if="jobs.length !== 0">
            <b-col sm="12" md="4" class="mb-5" v-for="job in jobs" :key="job.id"
              ><div class="jobs-card unavailable">
                <div class="job-title">
                  <p class="unavailable__text" v-if="job.type == 0">
                    {{ $t("jobsearch") }}
                  </p>
                  <h3>
                    <router-link
                      v-if="job.type == 1"
                      :to="{ name: 'job', params: { id: job.id } }"
                      >{{ job.title }}</router-link
                    >
                    <a v-if="job.type == 0">{{ job.title }}</a>
                  </h3>
                </div>
                <div class="details">
                  <p>
                    {{ job.sub_description }}
                  </p>
                </div>
                <div class="jobs-footer">
                  <ul>
                    <li>
                      <span><i class="fas fa-calendar-alt"></i></span>
                      <span>{{ shortenDate(job.created_at) }}</span>
                    </li>
                    <li>
                      <span><i class="fas fa-briefcase"></i></span>
                      <span v-if="job.presentation_forms_count">{{
                        job.presentation_forms_count
                      }}</span>
                      <span v-else>0</span>
                    </li>
                  </ul>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <b-container>
        <b-row>
          <b-col class="d-flex justify-content-center mb-5">
            <b-pagination
              size="lg"
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              aria-controls="my-table"
              class="mx-auto"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      jobs: [],
      isMobile: false,
      totalCount: "",
    };
  },
  watch: {
    currentPage: {
      handler: function () {
        this.getJobs();
      },
    },
  },
  computed: {
    rows() {
      return this.jobs.length;
    },
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University College  - Available Jobs`;
    document.querySelector('meta[name="title"]').setAttribute("content","Available Jobs");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","Available Jobs");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }else{
    document.title = `كلية النسور الجامعة  - الوظائف المتوفرة`;
    document.querySelector('meta[name="title"]').setAttribute("content","الوظائف المتوفرة");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","الوظائف المتوفرة");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getJobs();
  },
  methods: {
    getJobs() {
      let skip = this.currentPage - 1;
      this.axios
        .get(`job/clint?skip=${skip}&take=${this.perPage}`)
        .then((res) => {
          this.jobs = res.data.items;
          this.totalCount = res.data.totalCount;
        });
    },
    shortenDate(date) {
      let newDate = date.split("");
      newDate = newDate.slice(0, 10);
      newDate = newDate.join("");
      return newDate;
    },
  },
};
</script>
