<template>
  <div class="login">
    <!-- <video autoplay muted loop id="myVideo">
      <source src="@/assets/videos/bg.mp4" type="video/mp4" />
    </video> -->
    <img src="../../assets/images/bg.jpeg" alt="" />
    <div class="overlay"></div>
    <div class="login-form">
      <h2>مرحبا بكم في كلية النسور الجامعة</h2>
      <p>لانشاء حساب يرجى مليء الحقول الاتية</p>
      <b-form>
        <b-form-group
          id="input-group-1"
          label="الاسم الكامل:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.full_name"
            type="text"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="رقم الهاتف:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.phone"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-1"
          label="البريد الالكتروني:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.email"
            type="email"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="الرقم السري:"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="form.password"
            type="password"
            required
          ></b-form-input>
        </b-form-group>
        <div class="form-controls">
          <a @click="login">
            <button-fill text="انشاء حساب" />
          </a>
          <span>قمت بانشاء حساب مسبقا؟</span>
          <router-link :to="{ name: 'login' }">تسجيل الدخول</router-link>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
export default {
  title() {
    if (localStorage.getItem("lang") == "ar") {
      return "كلية النسور الجامعة";
    } else {
      return "Al-Nisour University College";
    }
  },
  components: {
    ButtonFill,
  },
  data() {
    return {
      form: {
        full_name: null,
        phone: null,
        email: null,
        password: null,
      },
    };
  },
  methods: {
    login() {
      if (
        this.form.email &&
        this.form.password &&
        this.form.full_name &&
        this.form.phone
      ) {
        this.axios
          .post("auth/registeration", this.form)
          .then((res) => {
            localStorage.setItem("NisourToken", res.data.message.token);
            this.$router.push("/");
          })
          .catch((err) => {
            if (err.response.data.email) {
              this.$swal(
                "البريد الالكتروني مستخدم يرجى التاكد من المعلومات",
                "",
                "warning"
              );
            } else if (err.response.data.phone) {
              this.$swal(
                "يرجى التاكد من رقم الهاتف",
                "رقم الهاتف غير صحيح",
                "warning"
              );
            } else {
              this.$swal("يرجى التاكد من مليء الحقول بشكل صحيح", "", "warning");
            }
          });
      } else {
        this.$swal("يرجى مليء جميع الحقول", "", "warning");
      }
    },
  },
};
</script>
