<template>
  <div class="application">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="ISO50001" />
    </header>
    <main>
    <section class="section-details">
        <b-container>
          <p class="mb-5">
            {{ $t("energy.e4") }}
          </p>
          <p class="mb-5">
            {{ $t("energy.e5") }}
          </p>
          <ul class="steps-ul">
            <li>{{ $t("energy.e6") }}</li>
            <li>{{ $t("energy.e7") }}</li>
            <li>{{ $t("energy.e8") }}</li>
          </ul>
        </b-container>
      </section>
      <section class="section-d">
        <b-container>
          <a class="links-button"
            :href="'energy-management-system'" 
            target="_blank"
          >
            <button-fill text="energy management system" />
          </a>
          <a class="links-button"
            :href="'energy-management-system'" 
            target="_blank"
          >
            <button-fill text="energy.e15" />
          </a>
          <a class="links-button"
            :href="'Energy-Researches'" 
            target="_blank"
          >
            <button-fill text="energy.e29" />
          </a>
          <a class="links-button"
            :href="'Energy-Workshops'" 
            target="_blank"
          >
            <button-fill text="energy.e31" />
          </a>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
    ButtonFill
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University College  - Dean Words`;
    document.querySelector('meta[name="title"]').setAttribute("content","Dean Words");
    document.querySelector('meta[name="description"]').setAttribute("content", "With pride and humility, Al-Nisour College has become a young university in its age, distinguished by its performance. Modern and advanced laboratories of international origin.");
    document.querySelector('meta[property="og:title"]').setAttribute("content","Dean Words");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "With pride and humility, Al-Nisour College has become a young university in its age, distinguished by its performance. Modern and advanced laboratories of international origin.");
    }else{
    document.title = `كلية النسور الجامعة  - كلمة العميد`;
    document.querySelector('meta[name="title"]').setAttribute("content","كلمة العميد");
    document.querySelector('meta[name="description"]').setAttribute("content", "بكل فخر و بكل تواضع اصبحت كلية النسور الجامعة الفتية بعمرها ، العريقة بأدائها ، فقد اصبحت كليتنا و بفترة قياسية تضاهي الجامعات العراقية و العربية العريقة ، لما تملكه من ملاكات تدريسية يتميزون بمهارات و خبرات علمية كبيرة و شهادات مرموقة و من تجهيزات و معدات و  تقنيات و مختبرات حديثة و متطورة و من مناشئ");
    document.querySelector('meta[property="og:title"]').setAttribute("content","كلمة العميد");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "بكل فخر و بكل تواضع اصبحت كلية النسور الجامعة الفتية بعمرها ، العريقة بأدائها ، فقد اصبحت كليتنا و بفترة قياسية تضاهي الجامعات العراقية و العربية العريقة ، لما تملكه من ملاكات تدريسية يتميزون بمهارات و خبرات علمية كبيرة و شهادات مرموقة و من تجهيزات و معدات و  تقنيات و مختبرات حديثة و متطورة و من مناشئ");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
  methods: {},
};
</script>

<style scoped>
.button-fill {
  font-size: 80%;
}

.dean-image {
  display: block;
  object-fit: cover;
  width: 70%;
  height: 50rem;
  margin: 0 auto 6rem auto;
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.5);

  border: 8px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #e41748, #4918cd);
}
</style>
