import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    depId: null,
  },
  mutations: {
    GET_DEP_ID(state, payload) {
      state.depId = payload;
    },
  },
  actions: {
    getDepId({ commit }, payload) {
      commit("GET_DEP_ID", payload);
    },
  },
  modules: {},
});
