import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import VueI18n from "vue-i18n";
import i18n from "./i18n";
import axios from "axios";
import VueAxios from "vue-axios";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import titleMixin from "./mixins/titleMixin";
import AOS from "aos";
import "aos/dist/aos.css";

Vue.use(VueAxios, axios);
Vue.mixin(titleMixin)

Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

// local css
import "@/assets/css/main.css";
// bootstrap
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import VueSocialSharing from "vue-social-sharing";

Vue.use(VueSocialSharing);
export const SID = 'service_3ksnmeh'
export const TID = 'template_bwrxehi'
export const AID = 'NXKa570Z16A-MOa_X'
const Url = "https://www.nuc.edu.iq/Al-Nisour/public/api/";
// const Url = "http://127.0.0.1:8000/api/";
export const filesUrl = "https://nuc.edu.iq/Al-Nisour/public/";

axios.interceptors.request.use(
    (config) => {
        config.baseURL = Url;
        config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "NisourToken"
    )}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// router.beforeEach((to, from, next) => {
//   let language = to.params.lang;
//   if (!language) {
//     language = "en";
//   }
//   i18n.locale = language;
//   next();
// });

const lang = localStorage.getItem("lang") || "ar";
document.documentElement.lang = lang;
axios.defaults.headers["Accept-Language"] = lang;

new Vue({
    created() {
        AOS.init();
    },
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");