<template>
  <div class="section section-what-to-study">
    <b-container>
      <b-row>
        <b-col sm="12" md="8" data-aos="slide-left">
          <div class="search">
            <div class="search-header">
              <h3>{{ $t("general.searchNews") }}</h3>
            </div>
            <div class="search-input">
              <label for="search">{{ $t("general.searchNewsLabel") }}</label>
              <div class="input">
                <input
                  type="text"
                  name="search"
                  v-model="search"
                  @keyup.enter="searchNews"
                />
                <i class="fas fa-search"></i>
              </div>
            </div>
            <div>
              <a href="#" @click="searchNews">
                <button-fill text="search" />
              </a>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="4" data-aos="slide-right">
          <div class="deps">
            <ul>
              <li v-for="dep in deps" :key="dep.id">
                <router-link
                  :to="{ name: 'about-dep', params: { id: dep.id } }"
                >
                  {{ dep.name }}
                  <img :src="getImage(dep.logo)" alt="" />
                </router-link>
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
export default {
  components: { ButtonFill },
  data() {
    return {
      search: null,
      deps: null,
    };
  },
  mounted() {
    this.getDeps();
  },
  methods: {
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    searchNews() {
      this.$router.push({ name: "blog", params: { search: this.search } });
    },
    getDeps() {
      this.axios.get("since/deparment/clint?skip=0&take=1000&type=5").then((res) => {
        this.deps = res.data.items;
      });
    },
  },
};
</script>
