<template>
  <div class="section section-news">
    <b-container>
      <b-row>
        <b-col>
          <h2>{{ $t("Latestnews") }}</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="4" v-for="(card, index) in cards" :key="card.id">
          <div
            class="section-news__card"
            data-aos="fade"
            :data-aos-delay="`${index + 5}00`"
          >
            <div class="card-image">
              <img :src="getImage(card.image)" alt="" />
            </div>
            <div class="card-content">
              <h3 class="title">
                <router-link :to="{ name: 'blog', params: { id: card.id } }">
                  {{ shortenTitle(card.title) }}
                </router-link>
              </h3>
              <p>{{ shortenText(card.sub_description) }}</p>
              <router-link :to="{ name: 'blog-post', params: { id: card.id } }">
                <button-fill text="readmore" />
              </router-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
export default {
  components: { ButtonFill },
  data() {
    return {
      cards: null,
    };
  },
  mounted() {
    this.getCards();
  },
  methods: {
    getCards() {
      this.axios.get("news/clint?skip=0&take=3").then((res) => {
        this.cards = res.data.items;
      });
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    shortenText(text) {
      if (text.length > 80) {
        let maxLength = 80;
        let trimmedString = text.substr(0, maxLength);
        return trimmedString + " ...";
      } else {
        return text;
      }
    },
    shortenTitle(text) {
      let maxLength = 70;

      let trimmedString = text.substr(0, maxLength);
      return trimmedString + "...";
    },
  },
};
</script>
