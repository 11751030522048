<template>
  <div class="application">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="learning" />
    </header>
    <main>
      <section class="section-details" data-aos="fade-up">
        <section class="section-details__manage">
          <b-container>
            <div>
              <p>{{ $t("learningInfo") }}</p>
            </div>
          </b-container>
        </section>
        <section class="section-details__missions">
          <b-container>
            <h4>{{ $t("learningTasks.des") }}</h4>
            <ol>
              <b-row>
                <b-col sm="12" md="6">
                  <div class="pl-md-5">
                    <li>{{ $t("learningTasks.li1") }}</li>
                    <li>
                      {{ $t("learningTasks.li2") }}
                    </li>
                    <li>
                      {{ $t("learningTasks.li3") }}
                    </li>
                  </div>
                </b-col>
                <b-col sm="12" md="6">
                  <div class="pr-md-5"></div>
                </b-col>
              </b-row>
            </ol>
          </b-container>
        </section>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University College  - E-Learning Unit`;
    document.querySelector('meta[name="title"]').setAttribute("content","E-Learning Unit");
    document.querySelector('meta[name="description"]').setAttribute("content", "The e-learning unit is one of the formations of Al-Nisour University College, which was created according to the minutes of the meeting of the College Council in its eighth session, held on (23/8/2020) based on Article (n) of 17 first of Law 25 of 2016 and according to the directives of the Ministry of Higher Education and Scientific research according to their book No. BT 5/3499 on (26/8/2020) to keep pace with the development in e-learning techniques in the college by introducing this new service to all members and students of the college, which contributes to the advancement of the educational process, improving outcomes and achieving The required academic quality, in addition to providing technical support to all target groups of students, faculty members, and faculty members. The unit is administratively linked to the scientific assistant.");
    document.querySelector('meta[property="og:title"]').setAttribute("content","E-Learning Unit");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "The e-learning unit is one of the formations of Al-Nisour University College, which was created according to the minutes of the meeting of the College Council in its eighth session, held on (23/8/2020) based on Article (n) of 17 first of Law 25 of 2016 and according to the directives of the Ministry of Higher Education and Scientific research according to their book No. BT 5/3499 on (26/8/2020) to keep pace with the development in e-learning techniques in the college by introducing this new service to all members and students of the college, which contributes to the advancement of the educational process, improving outcomes and achieving The required academic quality, in addition to providing technical support to all target groups of students, faculty members, and faculty members. The unit is administratively linked to the scientific assistant.");
    }else{
    document.title = `كلية النسور الجامعة  - وحدة التعليم الالكتروني`;
    document.querySelector('meta[name="title"]').setAttribute("content","وحدة التعليم الالكتروني");
    document.querySelector('meta[name="description"]').setAttribute("content", "وحدة التعليم الالكتروني هي احدى تشكيلات كلية النسور الجامعة و التي تم استحداثها بموجب محضر اجتماع مجلس الكلية بجلسته الثامنة المنعقدة في ( ٢٣ / ٨ / ٢٠٢٠ ) استنادا الى المادة ( ن ) من ١٧ اولا من قانون ٢٥ لسنة ٢٠١٦ و حسب توجيهات وزارة التعليم العالي و البحث العلمي بموجب كتابهم المرقم ب ت ٥/٣٤٩٩ في (٢٠٢٠/٨/٢٦) لمواكبة التطور في تقنيات التعليم الالكتروني في الكلية من خلال التعريف بهذه الخدمة الجديدة لكافة منتسبي الكلية من اعضاء و طلبة بما يسهم في رقي العملية التعليمية و تحسين المخرجات و تحقيق الجودة الاكاديمية المطلوبة بالاضافة الى تقديم  الدعم الفني لكافة الفئات  المستهدفة من طلبة و اعضاء هيئة التدريس و منتسبي الكلية و ترتبط الوحدة اداريا بالمعاون العلمي");
    document.querySelector('meta[property="og:title"]').setAttribute("content","وحدة التعليم الالكتروني");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "وحدة التعليم الالكتروني هي احدى تشكيلات كلية النسور الجامعة و التي تم استحداثها بموجب محضر اجتماع مجلس الكلية بجلسته الثامنة المنعقدة في ( ٢٣ / ٨ / ٢٠٢٠ ) استنادا الى المادة ( ن ) من ١٧ اولا من قانون ٢٥ لسنة ٢٠١٦ و حسب توجيهات وزارة التعليم العالي و البحث العلمي بموجب كتابهم المرقم ب ت ٥/٣٤٩٩ في (٢٠٢٠/٨/٢٦) لمواكبة التطور في تقنيات التعليم الالكتروني في الكلية من خلال التعريف بهذه الخدمة الجديدة لكافة منتسبي الكلية من اعضاء و طلبة بما يسهم في رقي العملية التعليمية و تحسين المخرجات و تحقيق الجودة الاكاديمية المطلوبة بالاضافة الى تقديم  الدعم الفني لكافة الفئات  المستهدفة من طلبة و اعضاء هيئة التدريس و منتسبي الكلية و ترتبط الوحدة اداريا بالمعاون العلمي");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
};
</script>
