<template>
  <div class="application">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="sus.sus64" />
    </header>
    <main>
      <section class="section-details">
        <b-container>
          <h4 class="mb-5">{{ $t("sus.sus64") }}</h4>
          <p class="mb-5">
            {{ $t("sus.sus65") }}
          </p>
        </b-container>
      </section>
      <section class="section-d">
        <b-container>
          <a class="links-button"
           :href="'continuse-development'" 
            target="_blank"
          >
            <button-fill text="sustainable" />
          </a>
          
          <a class="links-button"
            :href="'Sustainable-Meeting'" 
            target="_blank"
          >
            <button-fill text="sus.sus29" />
          </a>
          <a class="links-button"
           :href="'Sustainable-Investment-Policy'" 
            target="_blank"
          >
            <button-fill text="sus.sus1" />
          </a>
          <a class="links-button"
            :href="`Sustainable-procurement-procedures`"
            target="_blank"
          >
            <button-fill text="sus.sus3" />
          </a>
          <a class="links-button"
           :href="'Sustainability-Researches'" 
            target="_blank"
          >
            <button-fill text="susResearches" />
          </a>
          
          <a class="links-button"
           :href="'Sustainability-Courses'" 
            target="_blank"
          >
            <button-fill text="susCourses" />
          </a>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    ButtonFill,
    MobileNav,
  },
  data() {
    return {
      name: "",
      path: "lib/pdfjs/web/viewer.html",
      isMobile: false,
      host: window.location.host,
    };
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University College  - College Sustainability Policy Related to Donations and Funding`;
    document.querySelector('meta[name="title"]').setAttribute("content","College Sustainability Policy Related to Donations and Funding");
    document.querySelector('meta[name="description"]').setAttribute("content", "Al-Nisour University College is committed to a policy of donations and funding based on activating its commitment to the continuity of social and environmental development. This commitment depends on the college’s mission and specialization, as this commitment is converted into successful results on the efforts made by the college in the financial aspect, especially its participation in donations related to environmental and social issues, which contribute to achieving sustainable development goals. The college’s policy is to encourage sustainable development in All areas in which you can provide financial support for the purpose of helping to reduce poverty and improve people's lives, starting with students and college-related bodies. Emphasis is also placed on providing modern financial tools and mechanisms that allow the provision of financing for projects related to the environment and aspects related to climate issues, leading to a supportive and stimulating financial system for sustainability: it focuses on sustainable development plans");
    document.querySelector('meta[property="og:title"]').setAttribute("content","College Sustainability Policy Related to Donations and Funding");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "Al-Nisour University College is committed to a policy of donations and funding based on activating its commitment to the continuity of social and environmental development. This commitment depends on the college’s mission and specialization, as this commitment is converted into successful results on the efforts made by the college in the financial aspect, especially its participation in donations related to environmental and social issues, which contribute to achieving sustainable development goals. The college’s policy is to encourage sustainable development in All areas in which you can provide financial support for the purpose of helping to reduce poverty and improve people's lives, starting with students and college-related bodies. Emphasis is also placed on providing modern financial tools and mechanisms that allow the provision of financing for projects related to the environment and aspects related to climate issues, leading to a supportive and stimulating financial system for sustainability: it focuses on sustainable development plans");
    }else{
    document.title = `كلية النسور الجامعة  - سياسة الاستدامة للكلية المتعلقة بالتبرعات والتمويل`;
    document.querySelector('meta[name="title"]').setAttribute("content","سياسة الاستدامة للكلية المتعلقة بالتبرعات والتمويل");
    document.querySelector('meta[name="description"]').setAttribute("content", "تلتزم كلية النسور الجامعة بسياسة خاصة بالتبرعات والتمويل تنطلق من تفعيل التزامها باستمرارية التنمية الاجتماعية والبيئية. ويعتمد هذا الالتزام على مهمة الكلية واختصاصها حيث يتم تحويل هذا الالتزام إلى نتائج ناجحة على مجهودات التي تبذلها الكلية في الجانب المالي وخاصة مشاركتها في التبرعات التي تخص قضايا البيئة والقضايا الاجتماعية والتي تصب");
    document.querySelector('meta[property="og:title"]').setAttribute("content","سياسة الاستدامة للكلية المتعلقة بالتبرعات والتمويل");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "تلتزم كلية النسور الجامعة بسياسة خاصة بالتبرعات والتمويل تنطلق من تفعيل التزامها باستمرارية التنمية الاجتماعية والبيئية. ويعتمد هذا الالتزام على مهمة الكلية واختصاصها حيث يتم تحويل هذا الالتزام إلى نتائج ناجحة على مجهودات التي تبذلها الكلية في الجانب المالي وخاصة مشاركتها في التبرعات التي تخص قضايا البيئة والقضايا الاجتماعية والتي تصب");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  },
  methods: {},
};
</script>

<style scoped>
.button-fill {
  font-size: 80%;
}
</style>
