<template>
  <div class="mobile-nav">
    <div class="nav-header">
      <div class="logo-container">
        <div class="logo">
          <img src="../../assets/images/logo.png" alt="" />
        </div>
        <h1 class="mr-3 site-name">{{ $t("alnisour") }}</h1>
      </div>
      <div class="menu-icon">
        <i class="fas fa-bars" @click="showMenu = !showMenu"></i>
        <b-nav-item-dropdown :text="language" right>
          <b-dropdown-item @click="changeLang('ar')">AR</b-dropdown-item>
          <b-dropdown-item @click="changeLang('en')">EN</b-dropdown-item>
        </b-nav-item-dropdown>
      </div>
    </div>
    <div class="nav-menu" v-if="showMenu">
      <div class="nav-menu-header">
        <i class="fas fa-times" @click="showMenu = !showMenu"></i>
      </div>
      <div class="nav-menu-content">
        <ul class="nav-menu-ul">
          <li>
            <router-link :to="{ name: 'Home' }">
              {{ $t("Home") }}
            </router-link>
          </li>
          <li>
            <div class="li-header" v-b-toggle.collapse-1>
              <span>{{ $t("SubmissionAndAcceptance") }}</span>
              <i class="fas fa-chevron-left"></i>
            </div>
            <b-collapse id="collapse-1">
              <b-card>
                <ul class="sub-menu">
                  <li>
                    <router-link :to="{ name: 'application-steps' }">{{
                      $t("RegistrationMechanism")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'application-terms' }">{{
                      $t("AdmissionRequirements")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'application-transfer' }">{{
                      $t("ConditionsForTransferringBetweenUniversities")
                    }}</router-link>
                  </li>
                </ul>
              </b-card>
            </b-collapse>
          </li>
          <li>
            <div class="li-header" v-b-toggle.collapse-2>
              <span>{{ $t("EducationalDepartments") }}</span>
              <i class="fas fa-chevron-left"></i>
            </div>
            <b-collapse id="collapse-2">
              <b-card>
                <ul class="sub-menu">
                  <li v-for="(dep, index) in deps" :key="dep.id">
                    <a href="#" v-b-toggle="`collapse-${index + 5}`">
                      {{ dep.name }}
                    </a>
                    <b-collapse :id="`collapse-${index + 5}`" class="mt-2">
                      <b-card>
                        <ul class="sub-dropdown">
                          <li v-if="dep.teachers.length !== 0">
                            <a @click.prevent="toStaffId(dep.id)">{{
                              $t("teachingstaff")
                            }}</a>
                          </li>
                          <li>
                            <a @click.prevent="toTable(dep.id)">{{ $t("SectionTable") }}</a>
                          </li>
                          <li v-if="dep.about !== null">
                            <a @click.prevent="toAboutId(dep.id)">{{
                              $t("AboutTheDepartment")
                            }}</a>
                          </li>
                          <li v-if="dep.stages.length !== 0">
                            <a @click.prevent="toLectures(dep)">{{
                              $t("lectures")
                            }}</a>
                          </li>
                          <li>
                            <a @click.prevent="toDepResearches(dep)">{{
                              $t("depResearches")
                            }}</a>
                          </li>
                          <li>
                            <a @click.prevent="toResearches(dep)">{{
                              $t("studentsResearches")
                            }}</a>
                          </li>
                          <li>
                            <a @click.prevent="tograduated(dep)">{{
                              $t("std_graguate.studentsGraguate")
                            }}</a>
                          </li>
                        </ul>
                      </b-card>
                    </b-collapse>
                  </li>
                </ul>
              </b-card>
            </b-collapse>
          </li>
          <li>
            <div class="li-header" v-b-toggle.collapse-3>
              <span>{{ $t("managmentDeps") }}</span>
              <i class="fas fa-chevron-left"></i>
            </div>
            <b-collapse id="collapse-3">
              <b-card>
                <ul class="sub-menu">
                  <li>
                    <router-link :to="{ name: 'council' }">{{
                      $t("council")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'tech' }">{{
                      $t("tech")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'quality' }">{{
                      $t("quality")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'learning' }">{{
                      $t("learning")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'reg' }">{{
                      $t("reg")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'planing' }">{{
                      $t("planing")
                    }}</router-link>
                  </li>
                </ul>
              </b-card>
            </b-collapse>
          </li>
          <li>
            <router-link :to="{ name: 'courses' }">
              {{ $t("ContinuingEducationCenter") }}
            </router-link>
          </li>

          <li>
            <a @click="toLink('https://nuc.bis.edu.iq/student/login?to=/student')" target="_blank">{{
              $t("students.Bologna system")
            }}</a>
          </li>
          <li>
            <router-link :to="{ name: 'blog' }">
              {{ $t("NewsandEvents") }}
            </router-link>
          </li>
          <li>
            <a href="https://eduportalv2.nuc.edu.iq/#/" class="p-3" target="_blank">
              {{ $t("certificatecourse") }}
            </a>
          </li>
          <li>
            <a @click="toLink('https://portal.nuc.edu.iq/auth')" href="https://portal.nuc.edu.iq/auth"
              target="_blank">{{ $t("Nisourportal") }}</a>
          </li>
          <li>
            <a @click="toLink('https://library.nuc.edu.iq')" href="https://library.nuc.edu.iq" target="_blank">{{
              $t("library") }}</a>
          </li>
          <li>
            <router-link :to="{ name: 'contact-us' }">
              {{ $t("ContactUs") }}
            </router-link>
          </li>
          <li>
            <router-link :to="`/faq`">
              {{ $t("faq") }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'jobs' }">{{
              $t("Availablejobs")
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'job-application' }">
              {{ $t("JobApplication") }}
            </router-link>
          </li>
          <li>
            <div class="li-header" v-b-toggle.collapse-4>
              <span>{{ $t("Abouttheuniversity") }}</span>
              <i class="fas fa-chevron-left"></i>
            </div>
            <b-collapse id="collapse-4">
              <b-card>
                <ul class="sub-menu">
                  <li>
                    <router-link :to="{ name: 'agreements' }">
                      {{ $t("agreements") }}
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'fundation' }">{{
                      $t("foundation")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'management' }">
                      {{ $t("Collegeadministration") }}
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'organazation' }">
                      {{ $t("organazationChart") }}
                    </router-link>
                  </li>
                </ul>
              </b-card>
            </b-collapse>
          </li>
          <li v-if="isLoggedIn == true">
            <router-link :to="{ name: 'profile' }">
              {{ $t("Profile") }}
            </router-link>
          </li>
          <li v-if="isLoggedIn == false">
            <router-link :to="{ name: 'login' }">
              {{ $t("Login") }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      deps: null,
      isLoggedIn: false,
      showMenu: false,
      language: "AR",
    };
  },
  mounted() {
    if (localStorage.getItem("lang"))
      this.language = localStorage.getItem("lang").toUpperCase();
    else {
      localStorage.setItem("lang", "ar");
      this.language = localStorage.getItem("lang").toUpperCase();
    }
    this.getDeps();
    if (localStorage.getItem("NisourToken")) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
  },
  methods: {
    getDeps() {
      this.axios.get("since/deparment/clint?skip=0&take=1000&type=5").then((res) => {
        this.deps = res.data.items;
      });
    },
    changeLang(lang) {
      localStorage.setItem("lang", lang);
      window.location.reload();
      this.language = localStorage.getItem("lang").toUpperCase();
    },
    toAboutId(id) {
      if (this.$route.params.id == id) {
        this.$store.dispatch("getDepId", id);
        this.showMenu = false;
      } else {
        this.$store.dispatch("getDepId", id);
        this.$router.push({ name: "about-dep", params: { id: id } });
        this.showMenu = false;
      }
    },
    toStaffId(id) {
      if (this.$route.params.id == id) {
        this.$store.dispatch("getDepId", id);
        this.showMenu = false;
      } else {
        this.$store.dispatch("getDepId", id);
        this.$router.push({ name: "staff", params: { id: id } });
        this.showMenu = false;
      }
    },
    toLectures(dep) {
      if (this.$route.params.id == dep.id) {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "lectures",
          params: { id: dep.id, depName: dep.name },
        });
      } else {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "lectures",
          params: { id: dep.id, depName: dep.name },
        });
      }
    },
    toResearches(dep) {
      if (this.$route.params.id == dep.id) {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "students-research",
          params: { id: dep.id },
        });
      } else {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "students-research",
          params: { id: dep.id },
        });
      }
    },
    toDepResearches(dep) {
      if (this.$route.params.id == dep.id) {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "dep-researches",
          params: { id: dep.id },
        });
      } else {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "dep-researches",
          params: { id: dep.id },
        });
      }
    },
    tograduated(dep) {
      if (this.$route.params.id == dep.id) {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "students-graduated",
          params: { id: dep.id },
        });
      } else {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "students-graduated",
          params: { id: dep.id },
        });
      }
    },
    toTable(dep) {
      if (this.$route.params.id == dep.id) {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "table",
          params: { id: dep.id, depName: dep.name },
        });
      } else {
        this.$store.dispatch("getDepId", dep.id);
        this.$router.push({
          name: "table",
          params: { id: dep.id, depName: dep.name },
        });
      }
    },
  },
};
</script>

<style scoped></style>
