<template>
  <div class="courses application">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="ContinuingEducationCenter" />
    </header>
    <main>
      <section class="section-details" data-aos="fade-up">
        <b-container>
          <h4>
            {{ $t("Training curriculum for the academic year 2023-2024") }}
          </h4>
          <ul>
            <p>
              {{ $t("For the purpose of bridging the gap between Iraq and the world, the Ministry of Higher Education and Scientific Research has created private colleges, including Al-Nisour University College. And the technician according to quality standards and the accession of the Center for Continuing Education (training and development previously). Where the Education Center has contributed to the training of cadres of state employees and the private sector with computer programs and is still seeking to provide more through its training programs, and here we put in your hands our training curriculum for the academic year 2023-2024, hoping for your active participation") }}
            </p>
            <a href="#" @click="onClicked('uploads/Courses2023-2024.pdf')" ><p style="color:blue">{{ $t("Click here to view the workshops and seminars") }}</p></a>
            </ul>
          </b-container></section>
      <section class="section-details" data-aos="fade-up">
        <b-container>
          <h4>
            {{ $t("Training curriculum for the academic year 2022-2023") }}
          </h4>
          <ul>
            <p>
              {{ $t("For the purpose of bridging the gap between Iraq and the world, the Ministry of Higher Education and Scientific Research has created private colleges, including Al-Nisour University College. And the technician according to quality standards and the accession of the Center for Continuing Education (training and development previously). Where the Education Center has contributed to the training of cadres of state employees and the private sector with computer programs and is still seeking to provide more through its training programs, and here we put in your hands our training curriculum for the academic year 2022-2023, hoping for your active participation") }}
            </p>
            <a href="#" @click="onClicked('uploads/dates.PDF')" ><p style="color:blue">{{ $t("Click here to view the workshops and seminars") }}</p></a>
            </ul>
          </b-container></section>
      <section class="section-courses">
        <b-container>
          <b-row v-show="courses!=null">
            <b-col
              sm="12"
              md="4"
              class="mb-5"
              v-for="(course, index) in courses"
              :key="course.id"
            >
              <div
                class="courses-card"
                data-aos="fade"
                :data-aos-delay="`${index + 5}00`"
              >
                <div class="image-placeholder">
                  <img :src="getCourseImage(course.image)" alt="" />
                </div>
                <div class="content">
                  <h3>
                    <router-link
                      :to="{
                        name: 'course-profile',
                        params: { id: course.id },
                      }"
                      >{{ shortenTitle(course.name) }}
                    </router-link>
                  </h3>
                  <p>
                    {{ shortenText(course.description) }}
                  </p>
                  <div class="price">
                    <p v-if="course.price !== '0'">{{ course.price }}</p>
                    <!-- <p v-else>{{ $t("general.free") }}</p> -->
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <b-container>
        <b-row>
          <b-col class="d-flex justify-content-center mb-5">
            <b-pagination
              size="lg"
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              aria-controls="my-table"
              class="mx-auto"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
  },
  data() {
    return {
      perPage: 12,
      currentPage: 1,
      courses: null,
      isMobile: false,
      totalCount: "",
    };
  },
  watch: {
    currentPage: {
      handler: function () {
        this.getCourses();
      },
    },
  },
  computed: {
    rows() {
      return this.courses.length;
    },
  },
  mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University College  - Continuing Education Center`;
    document.querySelector('meta[name="title"]').setAttribute("content","Continuing Education Center");
    document.querySelector('meta[name="description"]').setAttribute("content", "The Continuing Education Center supervises the establishment of many seminars and workshops throughout the week");
    document.querySelector('meta[property="og:title"]').setAttribute("content","Continuing Education Center");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "The Continuing Education Center supervises the establishment of many seminars and workshops throughout the week");
    }else{
    document.title = `كلية النسور الجامعة  - مركز التعليم المستمر`;
    document.querySelector('meta[name="title"]').setAttribute("content","مركز التعليم المستمر");
    document.querySelector('meta[name="description"]').setAttribute("content", "يشرف مركز التعليم المستمر على اقامة العديد من الندوات و ورش العمل  على مدار الاسبوع");
    document.querySelector('meta[property="og:title"]').setAttribute("content","مركز التعليم المستمر");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "يشرف مركز التعليم المستمر على اقامة العديد من الندوات و ورش العمل  على مدار الاسبوع");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`);
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getCourses();
  },
  methods: {
    async getCourses() {
      let skip = this.currentPage - 1;
      await this.axios
        .get(`course/clint?skip=${skip}&take=${this.perPage}`)
        .then((res) => {
          this.totalCount = res.data.totalCount;
          this.courses = res.data.items
          // .filter((item) => {
          //   return item.status !== 0;
          // });
        });
    },
    getCourseImage(path) {
      return ` https://www.nuc.edu.iq/newbackend/public/${path}`;
    },
    shortenText(text) {
      if (text.length > 100) {
        let maxLength = 100;

        let trimmedString = text.substr(0, maxLength);
        return trimmedString + "...";
      } else {
        return text;
      }
    },
    shortenTitle(text) {
      if (text.length > 50) {
        let maxLength = 50;

        let trimmedString = text.substr(0, maxLength);
        return trimmedString + "...";
      } else {
        return text;
      }
    },
    onClicked(path) {
      window.open(`${filesUrl}/${path}`, "_blank");
    },
  },
};
</script>
