<template>
  <div class="blog-post">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <!-- <post-header :title="post.title" :date="shortenDate(post.created_at)" /> -->
      <div class="post-header">
        <b-container class="h-100">
          <b-row class="h-100">
            <b-col
              class="d-flex align-items-center justify-content-center h-100"
            >
              <div class="post-header__title">
                <h2>{{ post.title }}</h2>
                <p>
                  <span
                    >{{ $t("date") }}: {{ shortenDate(post.created_at) }}</span
                  >
                  
                </p>
              </div>
              <!-- <p>{{ post.description }}</p> -->
            </b-col>
          </b-row>
        </b-container>
      </div>
    </header>
    <main>
      <b-container class="mt-5">
        <b-row>
          <b-col sm="12" md="12" class="p-0">
            <div class="post-body">
    <div class="post-body__image">
      <img :src="getImage(post.image)" alt="" />
    </div>
    <div class="post-body__post-info">
      <p>
        <!-- <span v-if="post.length !== 0"
          >{{ $t("Posted") }} {{ post.user.full_name }}</span
        >
        <span> - </span> -->
        <span v-if="post.length !== 0"
          >{{ $t("date") }}: {{ shortenDate(post.created_at) }}</span
        >
      </p>
      <h2>{{ post.title }}</h2>
    </div>
    <div class="post-body__post-text" id="post-text"></div>
  </div>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
import { filesUrl } from "@/main";
import Quill from "quill";
// import AgreementsBody from "@/components/blog/AgreementsBody.vue";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";
export default {
  components: {
    Topbar,
    Navbar,
    FooterComponent,
    MobileNav,
    // AgreementsBody,
  },
  data() {
    return {
      post: [],
      comments: [],
      posts: [],
      popularPosts: [],
      tags: [],
      isMobile: false,
    };
  },
  mounted() {
    
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getPost();
  },
  methods: {
    getPost() {
      this.axios
        .get(`agreements/clint/${this.$route.params.id}`)
        .then((res) => {
          this.post = res.data.item[0];
          this.changeMeta(res.data.item)
          let data = [];
          data.push(JSON.parse(this.post.description));
          let article = document.createElement("article");
          let quill = new Quill(article, {});
          quill.enable(false);
          quill.setContents(data[0].ops);
          let discription = document.getElementById("post-text");
          discription.innerHTML = "";
          discription.appendChild(article);
        });
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    shortenDate(date) {
      let newDate = date.split("");
      newDate = newDate.slice(0, 10);
      newDate = newDate.join("");
      return newDate;
    },
    changeMeta(item){
      document.querySelector('meta[name="title"]').setAttribute("content",`${item.title}`);
      document.querySelector('meta[name="description"]').setAttribute("content", `${item.description}`);
      document.querySelector('meta[property="og:title"]').setAttribute("content",`${item.title}`);
      document.querySelector('meta[property="og:description"]').setAttribute("content", `${item.description}`);
      document.querySelector('meta[property="og:image"]').setAttribute("content", `${filesUrl}${item.image}`);
      // document.getElementById("icon").setAttribute("href",`${filesUrl}${item.image}`); 
      if(localStorage.getItem("lang")=="ar"){
      document.title = `كلية النسور الجامعة  - ${item.title}`;
      }else{
      document.title = ` Al-Nisour University College  - ${item.title}`;
      }
    }
  },
};
</script>
