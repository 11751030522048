<template>
  <div class="section section-commitments">
    <b-container>
      <b-row class="p-0">
        <!-- <b-col class="p-0">
          <div class="section-commitments__header">
            <h2>{{ $t("commitments") }}</h2>
          </div>
        </b-col> -->
      </b-row>
      <b-row class="p-0">
        <b-col sm="12" md="6" class="p-0" data-aos="fade-left">
          <div class="section-commitments__content">
            <div class="content-text">
              <h3>
                <a href="">{{ $t("deansWord") }}</a>
              </h3>
              <p>
                {{ $t("deanBreafMessage") }}
              </p>
              <router-link :to="{ name: 'dean-words' }">
                <button-fill text="readmore" />
              </router-link>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6" class="p-0">
          <div class="section-commitments__image">
            <img src="../../assets/images/dean.jpeg" alt="" />
          </div>
        </b-col>
      </b-row>
      <b-row class="p-0">
        <b-col sm="12" md="6" class="p-0 h-100">
          <div class="section-commitments__image">
            <img
              src="../../assets/images/img1.jpeg"
              alt=""
            />
          </div>
        </b-col>
        <b-col sm="12" md="6" class="p-0" data-aos="fade-right">
          <div class="section-commitments__content">
            <div class="content-text">
              <h3>
                <a href="">{{ $t("aboutCollage") }}</a>
              </h3>
              <p class="">
                {{ $t("fundetionDes") }}
              </p>
              <router-link :to="{ name: 'fundation' }">
                <button-fill text="readmore" />
              </router-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
export default {
  components: { ButtonFill },
};
</script>
