<template>
  <div class="post-header">
    <b-container class="h-100">
      <b-row class="h-100">
        <b-col class="d-flex align-items-center justify-content-center h-100">
          <div class="post-header__title">
            <h2 data-aos="fade-down">{{ $t(text) }}</h2>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  props: ["text"],
};
</script>
