<template>
  <div class="post-sheare">
    <div class="tags" v-if="tags.length !== 0">
      <span class="icon"><i class="fas fa-tag"></i></span
      ><span v-for="tag in tags" :key="tag.id">{{ tag.name }}</span>
    </div>
    <div class="social-links">
      <ShareNetwork
        network="facebook"
        :url="href"
        title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
        description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
        quote="The hot reload is so fast it\'s near instant. - Evan You"
        hashtags="vuejs,vite"
      >
        <span><i class="fab fa-facebook-f"></i></span>
      </ShareNetwork>
      <ShareNetwork
        network="twitter"
        :url="href"
        title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
        description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
        quote="The hot reload is so fast it\'s near instant. - Evan You"
        hashtags="vuejs,vite"
      >
        <span><i class="fab fa-twitter"></i></span>
      </ShareNetwork>
      <ShareNetwork
        network="email"
        :url="href"
        title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
        description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
        quote="The hot reload is so fast it\'s near instant. - Evan You"
        hashtags="vuejs,vite"
      >
        <span><i class="fas fa-envelope"></i></span>
      </ShareNetwork>
      <ShareNetwork
        network="linkedin"
        :url="href"
        title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
        description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
        quote="The hot reload is so fast it\'s near instant. - Evan You"
        hashtags="vuejs,vite"
      >
        <span><i class="fab fa-linkedin-in"></i></span>
      </ShareNetwork>
    </div>
  </div>
</template>

<script>
export default {
  props: ["tags"],
  data() {
    return {
      href: window.location.href,
    };
  },
  mounted() {},
};
</script>
