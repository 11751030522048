<template>
  <div class="dep-info research application">
    <header>
      <topbar />
      <navbar v-show="isMobile !== true" />
      <mobile-nav v-show="isMobile" />
      <section-header text="energy.e29" />
    </header>
    <main>
      <section class="section-deps">
        <b-container>
          <section data-aos="fade-left" class="section section-lessons">
            <h3>
              {{ $t("energy.e29") }}
            </h3>
            <div>
              <div class="tbl-header">
                <table cellpadding="0" cellspacing="0" border="0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{{ $t("researchTitle") }}</th>
                      <th>{{ $t("researcher") }}</th>
                      <th>{{ $t("dep") }}</th>
                      <th>{{ $t("date") }}</th>
                      <th>{{ $t("magazin2") }}</th>
                      <th>{{ $t("researchLink") }}</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="tbl-content">
                <table cellpadding="0" cellspacing="0" border="0">
                  <tbody>
                    <tr
                      v-for="(research, index) in installments"
                      :key="research.id"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ research.title }}</td>
                      <td>
                        <router-link
                          :to="{
                            name: 'staff-profile',
                            params: { id: research.teacher.id },
                          }"
                        >
                          {{ research.teacher.name }}
                        </router-link>
                      </td>
                      <td>
                        <router-link
                          :to="{
                            name: 'about-dep',
                            params: { id: research.department.id },
                          }"
                        >
                          {{ research.department.name }}
                        </router-link>
                      </td>
                      <td>{{ research.publish_date }}</td>
                      <td>{{ research.magazine }}</td>
                      <td>
                        <a :href="research.link"><i class="fas fa-link"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </b-container>
      </section>
      <b-container>
        <b-row>
          <b-col class="d-flex justify-content-center mb-5">
            <b-pagination
              size="lg"
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              class="mx-auto"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-container>
      
      <section class="section-d">
        <b-container>
          <a class="links-button"
            :href="'energy-management-system'" 
            target="_blank"
          >
            <button-fill text="energy management system" />
          </a>
          <a class="links-button"
            :href="'Energy-Team'" 
            target="_blank"
          >
            <button-fill text="energy.e15" />
          </a>
          <a class="links-button"
            :href="'ISO50001'" 
            target="_blank"
          >
            <button-fill text="ISO50001" />
          </a>
          <a class="links-button"
            :href="'Energy-Workshops'" 
            target="_blank"
          >
            <button-fill text="energy.e31" />
          </a>
        </b-container>
      </section>
    </main>
    <footer>
      <footer-component />
    </footer>
  </div>
</template>

<script>
// import { filesUrl } from "@/main.js";
import { mapState } from "vuex";
import MobileNav from "@/components/layout/MobileNav.component.vue";
import Topbar from "@/components/layout/Topbar.component.vue";
import Navbar from "@/components/layout/Navbar.component.vue";
import ButtonFill from "@/components/layout/ButtonFill.component.vue";
import SectionHeader from "@/components/layout/SectionHeader.component.vue";
import FooterComponent from "@/components/layout/FooterComponent.component.vue";

export default {
  components: {
    Topbar,
    Navbar,
    SectionHeader,
    FooterComponent,
    MobileNav,
    ButtonFill
  },
  data() {
    return {
      installments: null,
      totalInstallment: 0,
      isMobile: false,
      perPage: 10,
      currentPage: 1,
      totalCount: "",
    };
  },
  computed: { ...mapState(["depId"]) },
  watch: {
    depId: async function () {
      await this.getResearches();
    },
    currentPage: {
      handler: function () {
        this.getResearches();
      },
    },
  },
  async mounted() {
    if(localStorage.getItem("lang")=="en"){
    document.title = ` Al-Nisour University College  - sustainable development Researches`;
    document.querySelector('meta[name="title"]').setAttribute("content","sustainable development Researches");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","sustainable development Researches");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }else{
    document.title = `كلية النسور الجامعة  - بحوث التنمية المستدامة`;
    document.querySelector('meta[name="title"]').setAttribute("content","بحوث التنمية المستدامة");
    document.querySelector('meta[name="description"]').setAttribute("content", "");
    document.querySelector('meta[property="og:title"]').setAttribute("content","بحوث التنمية المستدامة");
    document.querySelector('meta[property="og:description"]').setAttribute("content", "");
    }
    document.querySelector('meta[property="og:image"]').setAttribute("content", `https://e-learn.nuc.edu.iq/images/logo.png`);
    // document.getElementById("icon").setAttribute("href",`${filesUrl}favicon.ico`); 
    if (window.innerWidth > 991) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getResearches();
  },
  methods: {
    async getResearches() {
      let skip = this.currentPage - 1;
      await this.axios
        .get(
          `researches/energy/clint?skip=${skip}&take=${this.perPage}`
        )
        .then((res) => {
          this.installments = res.data.items;
          this.totalCount = res.data.totalCount;
        });
    },
    
  },
};
</script>
